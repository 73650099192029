import {ResponsiveTable, TableColumnType} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'

import {useResponsiveBreakpoints} from '../../../../Molecules/Responsive.utils'
import {useFeaturesState} from '../../../../Organisms/Features'
import {usePermissions} from '../../../../Permissions'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {DeliveryCardList} from '../../components'
import {
  DeliveryArrivalTime,
  DeliveryLeftAt,
  DeliveryStatus,
  DeliveryTicketNumber,
  DeliveryVehicle
} from '../../components/DeliveryFields'
import {QuantityUomToSymbolComponent} from '../../Order.components'
import {Order} from '../../Order.types'

import {getDeliveryToggles} from './utils'

interface DeliveriesTableProps {
  order?: Order
  deliveries?: Delivery[]
  customerId: string
  analytics?: any
  setSelectedDelivery: (delivery: Delivery) => void
  isFetching: boolean
}

export const DeliveriesTable: React.FC<DeliveriesTableProps> = ({
  order,
  deliveries,
  customerId,
  analytics,
  setSelectedDelivery,
  isFetching
}) => {
  const {t} = useTranslation()
  const features = useFeaturesState()
  const permissions = usePermissions()
  const {smallScreen} = useResponsiveBreakpoints()

  if (!order) return null

  const toggles = getDeliveryToggles(features, permissions, order)
  const allColumns: TableColumnType<Delivery>[] = [
    {
      field: 'ticket-number',
      headerName: t('order.expand.deliveryNumber'),
      renderCell: (delivery: Delivery) => (
        <DeliveryTicketNumber
          delivery={delivery}
          toggles={toggles}
          customerId={customerId}
          analytics={analytics}
        />
      )
    },
    !toggles.isRMC
      ? {
          field: 'left-plant-at',
          headerName: toggles.isDeliveryLoadFinished
            ? t('order.expand.leftAt')
            : t('order.expand.shippingDate'),

          renderCell: (delivery: Delivery) => (
            <DeliveryLeftAt
              delivery={delivery}
              isDeliveryLoadFinished={toggles.isDeliveryLoadFinished}
              isSloCountry={toggles.isSloCountry}
              businessLine={order.businessLine}
            />
          )
        }
      : {
          field: 'arrival',
          headerName: t('order.expand.arrival'),
          renderCell: (delivery: Delivery) => <DeliveryArrivalTime delivery={delivery} />
        },
    {
      field: 'quantity',
      headerName: toggles.isRMC ? t('order.expand.quantity') : t('order.expand.qtyLoaded'),
      renderCell: (delivery: Delivery) => (
        <>
          {delivery.loadQuantity}{' '}
          <QuantityUomToSymbolComponent uom={delivery.loadQuantityUom || ''} />
        </>
      )
    },
    {
      field: 'plant-name',
      headerName: t('order.orderDetails.plantName'),
      renderCell: (delivery: Delivery) => delivery.plantName || '-'
    },
    {
      field: 'vehicle',
      headerName: t(`order.expand.modeoftransport.${order.modeOfTransport || 'others'}`),
      renderCell: (delivery: Delivery) => (
        <DeliveryVehicle
          delivery={delivery}
          customerId={customerId}
          toggles={toggles}
          analytics={analytics}
        />
      )
    },
    {
      field: 'status',
      headerName: t('order.orderDetails.statusLabel'),
      renderCell: (delivery: Delivery) => (
        <DeliveryStatus delivery={delivery} toggles={toggles} customerId={customerId} />
      )
    }
  ]

  if (!isFetching && !deliveries?.length) return null

  return (
    <div data-test-id="deliveries-table">
      <h3 style={{color: '#54708C', marginTop: 32}}>{t('order.deliveries')}</h3>
      {smallScreen ? (
        <DeliveryCardList
          customerId={customerId}
          deliveries={deliveries || []}
          order={order}
          toggles={toggles}
          viewAll
          analytics={analytics}
          loading={isFetching}
          onCardClick={(delivery) => setSelectedDelivery(delivery)}
        />
      ) : (
        <ResponsiveTable
          columns={allColumns}
          rows={deliveries || []}
          onRowClick={(row) => setSelectedDelivery(row)}
          onSort={() => {}}
          loading={isFetching}
          selectionEnabled={false}
          isRowSelectable={() => false}
          keyField="deliveryId"
          rowDetailsAvailable
        />
      )}
    </div>
  )
}

import React from 'react'

interface QuantityUomToSymbolComponentProps {
  uom?: string
}

const M3: React.FC = () => (
  <>
    m<sup>3</sup>
  </>
)

const CY: React.FC = () => (
  <>
    yd<sup>3</sup>
  </>
)

const TO: React.FC = () => <>t</>

export const QuantityUomToSymbolComponent: React.FC<QuantityUomToSymbolComponentProps> = ({
  uom
}) => {
  switch (uom) {
    case 'm3':
      return <M3 />

    case 'M3':
      return <M3 />

    case 'cy':
      return <CY />

    case 'CY':
      return <CY />

    case 'to':
      return <TO />

    case 'ton':
      return <TO />

    case 'TO':
      return <TO />

    case 'TON':
      return <TO />

    default:
      return <>{uom}</>
  }
}

import {getUtcOffset} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {RequestedDate, TimezoneWarning} from '../../components'
import {Order} from '../../Order.types'
import {getEventLocationOffset} from '../../Order.utils'

import {DetailsField} from './DetailsField'
import {formattedDate} from './utils'

interface RequestedDateFieldProps {
  order: Order
}

// workaround until RequestedDate component is refactored
const useStyles = makeStyles()(() => ({
  override: {
    '& .MuiTypography-subtitle1': {
      color: '#00274D !important',
      fontSize: '16px !important',
      lineHeight: '16px !important',
      fontWeight: '600 !important'
    },
    '& .MuiTypography-body2': {
      color: '#00274D !important',
      fontSize: '14px !important',
      lineHeight: '8px !important',
      fontWeight: '500 !important'
    }
  }
}))

export const RequestedDateField = ({order}: RequestedDateFieldProps) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {classes} = useStyles()

  const {shippingDate, schedules, businessLine} = order

  const userOffset = getUtcOffset(shippingDate)
  const eventLocationOffset = getEventLocationOffset(order)

  let value, subValue

  if (businessLine === 'RMC') {
    if (!schedules?.length) return null

    const requestedDate = formattedDate(
      schedules[0].scheduleStartDate,
      language,
      eventLocationOffset
    )

    value = requestedDate[0]
    subValue = (
      <>
        {requestedDate[1]}
        <TimezoneWarning
          t={t}
          order={order}
          userOffset={userOffset}
          eventLocationOffset={eventLocationOffset}
        />
      </>
    )
  } else {
    value = (
      <div className={classes.override}>
        <RequestedDate
          t={t}
          order={order}
          language={language}
          userOffset={userOffset}
          eventLocationOffset={eventLocationOffset}
        />
      </div>
    )
  }

  return (
    <DetailsField
      label={t('order.orderDetails.requestedDate')}
      dataTestId="requested-date"
      value={value}
      subValue={subValue}
    />
  )
}

import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {BusinessLineType} from '../../../../common/types'

export const useDelivery = (
  orderId?: string,
  businessLine?: 'CEM' | 'AGG' | 'RMC',
  customerId?: string,
  isPumpServiceIncluded?: boolean
) => {
  return useQuery(
    ['deliveries', {orderId, customerId}],
    async () => {
      const res: AxiosResponse<Delivery[]> = await api.get('/deliveries', {
        params: {
          orderId,
          customerId,
          ...(isPumpServiceIncluded ? {includePumpService: true} : {})
        }
      })
      return res.data
    },
    {
      enabled: !!orderId && !!customerId && businessLine === BusinessLineType.CEM
    }
  )
}

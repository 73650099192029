import {TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {BusinessLineType} from '../../../common/types'
import {FieldDetails, FieldValue} from '../../../Molecules/FieldDetails'
import {Features} from '../../../Organisms/Features'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {QuantityUomToSymbolComponent} from '../Order.components'
import {Order, ShippingType} from '../Order.types'

import {
  DeliveryArrivalTime,
  DeliveryLeftAt,
  DeliveryStatus,
  DeliveryTicketNumber,
  DeliveryUnloadFinished,
  DeliveryVehicle,
  PricingInformation,
  TimeAndDateDisplayedForDoneColumn
} from './DeliveryFields'

const useStyles = makeStyles()(() => ({
  card: {
    width: '100%',
    marginBottom: 8,
    paddingTop: 2,
    paddingBottom: 2
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
}))

export interface DeliveryTogglesType {
  isDeliveryLoadFinished: boolean
  epodEnabledForOrderExpand: boolean
  isCustomerDeliveryNumber: boolean
  isSloCountry: boolean
  isFilteringOutCancelledDeliveries: boolean
  aggColumnsForRmc: boolean
  useTruckLicensePlate: boolean
  isCEM?: boolean
  isAGG?: boolean
  isRMC?: boolean
  hasInvoicePermission: boolean
  isInvoiceDetailsDisabled: (invoiceId: string, country?: string) => boolean
}
export interface DeliveryCardType {
  delivery: Delivery
  viewAll?: boolean
  toggles: DeliveryTogglesType
  customerId: string
  order: Order
  analytics?: any
  onCardClick?: (delivery: Delivery) => void
}

export interface DeliveriesListType {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error?: Error | unknown
  deliveries: Delivery[]
  loading?: boolean
  toggles: DeliveryTogglesType
  customerId: string
  viewAll: boolean
  setViewAll: React.Dispatch<React.SetStateAction<boolean>>
  order: Order
  analytics?: any
  onCardClick?: (delivery: Delivery) => void
}

type DeliveryCardListType = Omit<DeliveriesListType, 'setViewAll'>

export const DeliveryCard: React.FC<DeliveryCardType> = ({
  delivery,
  viewAll = false,
  toggles,
  customerId,
  analytics,
  order,
  onCardClick
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const modeOfTransportLabel =
    delivery.modeOfTransport !== undefined ? delivery.modeOfTransport : 'others'
  const {eticket: {ageOfConcrete = 0} = {}} = delivery || {}

  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      style={{backgroundColor: '#f7f7f7'}}
      onClick={() => onCardClick && onCardClick(delivery)}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Grid container justifyContent="flex-end">
            <Grid item xs={6}>
              <Box data-test-id="ticket-number">
                <FieldValue
                  label={t('order.expand.deliveryNumber')}
                  dataTestId="ticket-number-field"
                  value={
                    <DeliveryTicketNumber
                      delivery={delivery}
                      toggles={toggles}
                      customerId={customerId}
                      analytics={analytics}
                    />
                  }
                  gutter
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box data-test-id="ticket-status" mb={1.75} mt={1}>
                <DeliveryStatus delivery={delivery} toggles={toggles} customerId={customerId} />
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <FieldDetails
                dataTestId={`leftAt-${delivery.deliveryId}`}
                label={
                  toggles.isDeliveryLoadFinished
                    ? t('order.expand.leftAt')
                    : t('order.expand.shippingDate')
                }
                value={
                  <DeliveryLeftAt
                    delivery={delivery}
                    isDeliveryLoadFinished={toggles.isDeliveryLoadFinished}
                    isSloCountry={toggles.isSloCountry}
                    businessLine={order.businessLine}
                  />
                }
                businessLine={order.businessLine}
                showForBusinesslines={[BusinessLineType.CEM, BusinessLineType.AGG]}
                gutter
                fullWidth
              />

              <FieldDetails
                dataTestId={`arrival-${delivery.deliveryId}`}
                label={t('order.expand.arrival')}
                businessLine={order.businessLine}
                showForBusinesslines={[BusinessLineType.RMC]}
                value={<DeliveryArrivalTime delivery={delivery} />}
                gutter
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FieldDetails
                dataTestId={`unloadFinished-${delivery.deliveryId}`}
                businessLine={order.businessLine}
                label={t('order.expand.deliveredOn')}
                showForBusinesslines={[BusinessLineType.AGG]}
                shippingType={order.shippingType}
                showForShippingType={ShippingType.Deliver}
                gutter
                fullWidth
                value={<DeliveryUnloadFinished delivery={delivery} />}
              />
              <Features name="AggColumnsForRmc" resolution="disabled">
                <FieldDetails
                  dataTestId={`done-${delivery.deliveryId}`}
                  label={t('order.expand.deliveredOn')}
                  value={<TimeAndDateDisplayedForDoneColumn delivery={delivery} />}
                  businessLine={order.businessLine}
                  showForBusinesslines={[BusinessLineType.RMC]}
                  gutter
                  fullWidth
                />
              </Features>
            </Grid>
          </Grid>

          <Grid container data-test-id="delivery-vehicle-collected">
            <Grid item xs={6}>
              <Box data-test-id="quantity">
                <FieldValue
                  label={toggles.isRMC ? t('order.expand.quantity') : t('order.expand.qtyLoaded')}
                  gutter
                  value={
                    <Box>
                      {delivery.loadQuantity}
                      {delivery.loadQuantityUom ? (
                        <>
                          {' '}
                          <QuantityUomToSymbolComponent uom={delivery.loadQuantityUom} />
                        </>
                      ) : null}
                    </Box>
                  }
                  dataTestId="loadQuantity-value"
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FieldValue
                label={t(`order.expand.modeoftransport.${modeOfTransportLabel}`)}
                dataTestId="vehicle"
                value={
                  <DeliveryVehicle
                    delivery={delivery}
                    customerId={customerId}
                    toggles={toggles}
                    analytics={analytics}
                  />
                }
                gutter
              />
            </Grid>
          </Grid>

          {delivery.returnedDelivery && (
            <Grid container data-test-id="delivery-returned-delivery">
              <Grid item xs={6}>
                <Box data-test-id="returned-quantity">
                  <FieldValue
                    label={t('order.expand.returnedDeliveryQuantity')}
                    gutter
                    value={
                      <Box>
                        {delivery.returnedDelivery.quantity}
                        {delivery.returnedDelivery.unit && (
                          <>
                            {' '}
                            <QuantityUomToSymbolComponent uom={delivery.returnedDelivery.unit} />
                          </>
                        )}
                      </Box>
                    }
                    dataTestId="returned-quantity-value"
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={6} sm={6}>
                <FieldValue
                  label={t('order.expand.returnedDeliveryNumber')}
                  gutter
                  dataTestId="returned-id"
                  value={delivery.returnedDelivery.deliveryId?.split('.')[1]}
                />
              </Grid>
            </Grid>
          )}

          {delivery.pricing ? (
            <Grid container data-test-id="delivery-cost-rate">
              <Grid item xs={6}>
                <Box data-test-id="cost-rate">
                  <FieldValue
                    label={t('invoiceDetail.lineItems.expand.unitPrice')}
                    gutter
                    value={<PricingInformation delivery={delivery} />}
                    dataTestId="returned-quantity-value"
                  />
                </Box>
              </Grid>
            </Grid>
          ) : null}

          <Box mb={2} data-test-id="plant-name">
            <FieldValue
              label={t('order.orderDetails.plantName')}
              dataTestId="delivery-plant-details"
            >
              {delivery.plantName || '-'}
            </FieldValue>
          </Box>
          {ageOfConcrete > 0 && order?.businessLine === BusinessLineType.RMC ? (
            <Box mb={2} data-test-id="age-of-concrete">
              <FieldValue
                label={t('order.orderDetails.ageOfConcrete')}
                dataTestId="age-of-concrete-details"
              >
                {`${ageOfConcrete} ${t('order.orderDetails.minutes')}`}
              </FieldValue>
            </Box>
          ) : null}

          {order.isHaveMoreThanOne && (
            <Box data-test-id="sales-order-number">
              <FieldValue
                label={t('order.orderDetails.orderNumber')}
                dataTestId="delivery-sales-order-number"
              >
                {delivery.orderNumber}
              </FieldValue>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export const DeliveryCardList: React.FC<DeliveryCardListType> = ({
  deliveries,
  viewAll = false,
  toggles,
  customerId,
  order,
  analytics,
  onCardClick
}) => {
  return (
    <Box data-test-id="deliveries-list" my={1.75}>
      {deliveries.map((delivery) => (
        <DeliveryCard
          key={delivery.deliveryId}
          delivery={delivery}
          viewAll={viewAll}
          toggles={toggles}
          customerId={customerId}
          order={order}
          analytics={analytics}
          onCardClick={onCardClick}
        />
      ))}
    </Box>
  )
}

import {Typography} from '@hconnect/uikit'
import {Grid, LinearProgress} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {InfoTextWithIcon} from '../../../Molecules/InfoTextWithIcon'
import {Widget} from '../../../Molecules/Widget/Widget'
import {useIsFeatureEnabledForCurrentCustomer} from '../../../OrderIntake/FeatureFlags'
import {CustomerStateType, selectCustomers} from '../../../Organisms/Customers'
import {AppState} from '../../../Root.store'

import {ScheduleNewCollect} from './ScheduleNewCollect'
import {ScheduleNewDelivery} from './ScheduleNewDelivery'

interface Type {
  isOrderIntakeError?: boolean
  isFetchingOrderIntakeOptions?: boolean
  isPermissionToOrderForSelectedCustomer?: boolean
  isNoAvailableContracts?: boolean
  isDelivery?: boolean
  isCollect?: boolean
}
// eslint-disable-next-line complexity
export const OrderIntakeWidget = ({
  isOrderIntakeError,
  isFetchingOrderIntakeOptions,
  isPermissionToOrderForSelectedCustomer,
  isNoAvailableContracts,
  isDelivery,
  isCollect
}: Type) => {
  const {t} = useTranslation()
  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const {selectedCustomer} = customersState
  const bothDeliveryAndCollect = isDelivery && isCollect
  const isDeliverDisabled = useIsFeatureEnabledForCurrentCustomer(
    selectedCustomer?.customerId,
    'OrderIntakeDisableDelivered'
  )
  if (isOrderIntakeError && !isFetchingOrderIntakeOptions) {
    return (
      <Grid item xs={12} sm={6}>
        <Widget title={t('overview.placeNewOrder')} data-test-id="widget-order-intake-error">
          <Typography variant="h4" color="error">
            {t('orderIntake.errorTitle')}
          </Typography>
          <Typography variant="caption" color="error">
            {t('orderIntake.errorMessage')}
          </Typography>
        </Widget>
      </Grid>
    )
  }
  if (
    !isPermissionToOrderForSelectedCustomer &&
    !isFetchingOrderIntakeOptions &&
    isNoAvailableContracts
  ) {
    return (
      <Grid item xs={12} sm={6}>
        <Widget title={t('overview.placeNewOrder')} data-test-id="widget-order-intake-no-contracts">
          <Typography variant="caption" color="error">
            {t('orderIntake.notAllowedToPlaceOrderForThisCustomer')}
          </Typography>
        </Widget>
      </Grid>
    )
  }
  if (
    isPermissionToOrderForSelectedCustomer &&
    isNoAvailableContracts &&
    !isFetchingOrderIntakeOptions
  ) {
    return (
      <Grid item xs={12} sm={6}>
        <Widget title={t('overview.placeNewOrder')} data-test-id="widget-order-intake-no-contracts">
          <InfoTextWithIcon text={t('orderIntake.noAvailableContracts')} />
        </Widget>
      </Grid>
    )
  }
  if (isFetchingOrderIntakeOptions) {
    return (
      <Grid item xs={12} md={12}>
        <Widget title={t('overview.placeNewOrder')} data-test-id="widget-order-intake-loading">
          <LinearProgress data-test-id="widget-order-intake-loader" />
          <Typography variant="caption">{t('overview.loadingContracts')}</Typography>
        </Widget>
      </Grid>
    )
  }
  if (isDelivery && !isDeliverDisabled && !isCollect && !isFetchingOrderIntakeOptions) {
    return (
      <Grid item xs={12} md={12}>
        <Widget
          title={t('overview.scheduleNewDelivery')}
          data-test-id="widget-schedule-new-delivery"
        >
          <ScheduleNewDelivery />
        </Widget>
      </Grid>
    )
  }
  if (isCollect && !isDelivery && !isFetchingOrderIntakeOptions) {
    return (
      <Grid item xs={12} md={12}>
        <Widget
          title={t('overview.orderIntakeCollectWidgetTitle')}
          subTitle={t('orderIntake.collect.generateQrCodeCaption')}
          data-test-id="widget-schedule-new-delivery"
        >
          <ScheduleNewCollect />
        </Widget>
      </Grid>
    )
  }
  if (bothDeliveryAndCollect) {
    return (
      <Grid item xs={12} md={12} container columnSpacing={{md: 2}} rowSpacing={{xs: 2, md: 3}}>
        {!isDeliverDisabled && (
          <Grid item xs={12} md={6}>
            <Widget
              title={t('overview.scheduleNewDelivery')}
              data-test-id="widget-schedule-new-delivery"
            >
              <ScheduleNewDelivery />
            </Widget>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Widget
            title={t('overview.orderIntakeCollectWidgetTitle')}
            subTitle={t('orderIntake.collect.generateQrCodeCaption')}
            data-test-id="widget-schedule-new-delivery"
          >
            <ScheduleNewCollect />
          </Widget>
        </Grid>
      </Grid>
    )
  }
  return null
}

import {first} from 'lodash'

import {BusinessLineType} from '../../../common/types'
import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {OrderIntakeOptions} from '../../../OrderIntake/declarations/types'
import * as options from '../../../OrderIntake/Options'
import {getDefaultMaterial} from '../../../OrderIntake/Options'
import {useFeaturesState} from '../../../Organisms/Features'

export const useBusinessLineByCustomerId = (
  customerId: string | undefined,
  shippingType: string
): BusinessLineType | '' => {
  const {getFeature} = useFeaturesState()

  const isSlotsManagementEnabled = getFeature('OrderIntakeSlotsDeliver')
  const useConfigurableSlots = getFeature('OrderIntakeUseConfigurableSlots')
  const nextDayCollect = getFeature('OrderIntakeChangeOrderDefaultDayCollect')
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const configuration = {
    isSlotsManagementEnabled,
    useConfigurableSlots,
    nextDayCollect,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }

  const getDefaultOption = (options: OrderIntakeOptions) => first(Object.values(options))

  const getBusinessLine = (options?: OrderIntakeOptions) => {
    if (options === undefined) return ''
    const option = getDefaultOption(options)
    if (option === undefined) return ''
    return getDefaultMaterial(option)?.material.businessLine ?? ''
  }

  const {data: deliverOptions} = options.useDeliveryOptions(configuration, customerId)
  const businessLineDeliver = getBusinessLine(deliverOptions)

  const {data: collectOptions} = options.useCollectionOptions(configuration, customerId)
  const businessLineCollect = getBusinessLine(collectOptions)

  return shippingType === (ShippingType.DELIVER as string)
    ? businessLineDeliver
    : businessLineCollect
}

import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Box, Button, Theme} from '@material-ui/core'
import {green} from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {selectCustomers} from '../../../Organisms/Customers'
import {CustomerStateType} from '../../../Organisms/Customers/Action.types'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {useRolesByCustomerId} from '../../../Roles'
import {AppState} from '../../../Root.store'
import {getUserProfile} from '../../../UserProfile/UserProfile.selectors'
import {useBusinessLineByCustomerId} from '../../Order/hooks/useBusinessLineByCustomerId'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonInline: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

export const ScheduleNewCollect: React.FC = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useStyles()
  const customerState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const selectedCustomer = customerState.selectedCustomer
  const {isError, isFetching} = useBulkCementOrderIntake()
  const {data: roles} = useRolesByCustomerId(selectedCustomer?.customerId)
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const businessLineCollect = useBusinessLineByCustomerId(
    selectedCustomer?.customerId,
    ShippingType.COLLECT
  )

  const handleOpenCollect = () => {
    history.push('/bulkCementCollection')
    trackEvent('hubOrderIntakeStart', {
      product: 'hub',
      customerId: selectedCustomer?.customerId,
      customerName: selectedCustomer?.customerName,
      businessLine: businessLineCollect,
      userCountry: userProfile?.country,
      userId: userProfile?.user_id,
      role: roles.map((role) => role.roleType).join(','),
      shippingType: ShippingType.COLLECT,
      entryPoint: 'Overview'
    })
  }

  if (!selectedCustomer) {
    return null
  }

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <div className={classes.wrapper}>
        <Button
          color="primary"
          onClick={handleOpenCollect}
          disabled={isError || isFetching}
          data-test-id="order-intake-collect-button"
        >
          {t('overview.generateQrCode')}
        </Button>
      </div>
    </Box>
  )
}

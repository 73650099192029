import {combineReducers} from 'redux'

import {
  ORDERS_CLEAR,
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_SUCCESS,
  OrderAbstractState,
  OrderDeliveriesStateType,
  OrdersActionTypes,
  deliveries,
  orders
} from '../../Organisms/Orders'
import {concatIds} from '../../util/reducer.helper'

import {OrderPageActionTypes, OrderPageState} from './Action.types'
import {ORDERS_CLEAR_VIEW, ORDERS_SWITCH_VIEW} from './Order.action'
import {OrderViews} from './Order.enum'

const initialState: OrderPageState = {
  currentView: OrderViews.DEFAULT,
  [OrderViews.DEFAULT]: [],
  [OrderViews.LOOKUP]: []
}

export const view = (state = initialState, action: OrderPageActionTypes | OrdersActionTypes) => {
  switch (action.type) {
    case ORDERS_SWITCH_VIEW:
      return {
        ...state,
        [state.currentView]: action.clearPrevView ? [] : state[state.currentView],
        currentView: OrderViews[action.view] || OrderViews.DEFAULT
      }

    case ORDERS_CLEAR_VIEW:
      return {
        ...state,
        [state.currentView]: []
      }

    case ORDERS_FETCH_SUCCESS: {
      const isLookup = !!action.params['orderId']
      const collectionIds = action.payload.map((item) => item.orderId)
      return {
        ...state,
        [state.currentView]:
          action.params.skip > 0 || isLookup
            ? concatIds(state[state.currentView], collectionIds)
            : collectionIds
      }
    }

    case ORDERS_FETCH_FAILURE:
      return {
        ...state,
        [state.currentView]: []
      }

    case ORDERS_CLEAR:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export interface OrderState {
  orders: OrderAbstractState
  deliveries: OrderDeliveriesStateType
  view: OrderPageState
}

export default combineReducers<OrderState>({
  orders,
  deliveries,
  view
})

import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {Customer} from '../../../Organisms/Customers/Customers.types'
import {Features} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'
import {showPrevMonthInvoices, showPrevQuarterInvoices} from '../../Finance/Invoices/Invoice.action'

const useStyles = makeStyles(() => ({
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export const InvoiceByDate: React.FC = () => {
  const dispatch = useDispatch()
  const customer = useSelector<AppState, Customer | null>(
    (state) => state.customers.selectedCustomer
  )
  const classes = useStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  if (!customer) {
    return null
  }
  return (
    <div className={classes.alignRight}>
      <Button color="inherit" onClick={() => dispatch(showPrevMonthInvoices(language, customer))}>
        {t('overview.prevMonth')}
      </Button>
      <Features name="PreviousQuarterInvoices">
        <Button
          color="inherit"
          style={{marginLeft: 16}}
          onClick={() => dispatch(showPrevQuarterInvoices(language, customer))}
        >
          {t('overview.prevQuarter')}
        </Button>
      </Features>
    </div>
  )
}

import {Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

import {dateFormatter, timeFormatter} from '@hconnect/uikit'
import {Order} from '../Order.types'

interface PlacedDateProps {
  order: Order
}

export const PlacedDate: React.FC<PlacedDateProps> = ({order: {placedDate}}) => {
  const {
    i18n: {language}
  } = useTranslation()

  const orderPlacedDate = placedDate ? dateFormatter(placedDate, language) : ''
  const orderPlacedTime = placedDate ? timeFormatter(placedDate, language) : ''

  return (
    <>
      <Typography color="textPrimary" variant="subtitle1">
        {orderPlacedDate}
      </Typography>
      <Typography color="textPrimary" variant="body2">
        {orderPlacedTime}
      </Typography>
    </>
  )
}

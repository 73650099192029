import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {BusinessLineType} from '../../../../common/types'
import {useFeaturesState} from '../../../../Organisms/Features'
import {Plant} from '../../../../Organisms/Plants/Plants.types'

export const usePlants = (
  businessLine?: 'CEM' | 'AGG' | 'RMC',
  plantName?: string | null,
  plantId?: string | null
) => {
  const {getFeature} = useFeaturesState()
  const isPlantsById = getFeature('PlantsById')

  return useQuery(
    ['plants', {plantId}],
    async () => {
      if (isPlantsById) {
        const res: AxiosResponse<Plant> = await api.get(`/plants/${plantId}`)
        return res.data
      }
      const plantIdSplitted = plantId?.split('.')
      const res: AxiosResponse<Plant[]> = await api.get('/plants', {
        params: {
          plantType: 'E'
        }
      })
      return res.data.find((plant) =>
        plantIdSplitted && plantIdSplitted.length && plantIdSplitted[1]
          ? plant.plantId === plantIdSplitted[1]
          : plant.plantName === plantName
      )
    },
    {
      enabled: !!plantName && businessLine === BusinessLineType.CEM
    }
  )
}

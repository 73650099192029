import {useRolesLogic} from '@hconnect/common/Invite/hooks/useRoles'
import {Box, Button} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Customer} from '../../../Organisms/Customers/Customers.types'

interface Props {
  selectedCustomer: Customer
}

export const InviteButton: React.FC<Props> = ({selectedCustomer}) => {
  const {t} = useTranslation()
  const history = useHistory()

  const {rolesToGenerate} = useRolesLogic()

  const selectedRole = rolesToGenerate.find(
    (role) =>
      role.dataScope.customerIds?.includes(selectedCustomer.customerId) ||
      role.dataScope.customerId?.includes(selectedCustomer.customerId) ||
      role.dataScope.payerIds?.includes(selectedCustomer.customerId) ||
      role.dataScope.payerId?.includes(selectedCustomer.customerId)
  )

  const hasCustomerAssignments =
    !!selectedRole?.dataScope.customerIds || !!selectedRole?.dataScope.customerId
  const hasPayerAssignments =
    !!selectedRole?.dataScope.payerIds || !!selectedRole?.dataScope.payerId
  const hasCustomerOrPayerAssignments = hasCustomerAssignments || hasPayerAssignments

  const hasNoAssignments =
    rolesToGenerate.filter(
      (value) =>
        value.dataScope.customerIds ||
        value.dataScope.customerId ||
        value.dataScope.payerId ||
        value.dataScope.payerIds
    ).length > 0 && !selectedRole
      ? false
      : !selectedRole

  const invitePossible = hasCustomerOrPayerAssignments || hasNoAssignments

  const isBusinessOwner = selectedRole?.roleType === 'BUSINESS_OWNER'

  const queryParams = new URLSearchParams({})

  if (hasNoAssignments || hasCustomerAssignments) {
    queryParams.append('searchType', 'customer')
  } else if (hasPayerAssignments) {
    queryParams.append('searchType', 'payer')
  }

  if (selectedCustomer.customerNumber) {
    queryParams.append('searchId', selectedCustomer.customerNumber)
  }

  if (selectedRole?.dataScope.countryId) {
    queryParams.append('countryId', selectedRole?.dataScope.countryId)
  }

  const goToInvitePage = () => {
    history.push(`/createInvite?${queryParams.toString()}`)
  }

  return (
    <Box display="flex" mt={2}>
      {rolesToGenerate.length > 0 ? (
        <Button
          variant="outlined"
          color="inherit"
          onClick={goToInvitePage}
          data-test-id="invite-create-inviteToThis"
          disabled={!invitePossible}
        >
          {isBusinessOwner ? t('invite.create.addNewUserToThis') : t('invite.create.inviteToThis')}
        </Button>
      ) : null}
    </Box>
  )
}

import {useMemo} from 'react'

import {useFeaturesState} from '../../../Organisms/Features'
import {Order} from '../Order.types'

import {useDeliveriesByOrderId} from './useDeliveriesByOrderId'

export const useCheckOrdersWithDeliveries = (order: Order) => {
  const {getFeature} = useFeaturesState()

  const isGroupedDeliveries = getFeature('OrderIntakeGroupedDeliveries')
  const useHaulierView = getFeature('UseHaulierView')
  const isFilteringOutCancelledDeliveries = getFeature('ExcludeAllCancelledDeliveries') || getFeature('ExcludeNotLoadedCancelledDeliveries')
  const isPumpServiceIncluded = getFeature('IncludePumpService')

  const {isFetching: isDeliveriesFetching, data: deliveries} = useDeliveriesByOrderId(
    order.customer.customerId,
    order.orderId,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries,
    order?.orderIds,
    isPumpServiceIncluded,
    useHaulierView,
    'deliveryNumber',
    'desc'
  )

  return useMemo(
    () => ({
      isDeliveriesFetching,
      hasDeliveries: Boolean(deliveries?.length)
    }),
    [isDeliveriesFetching, deliveries?.length]
  )
}

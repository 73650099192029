import c60TopBannerSm from '../../../Assets/c60_page_assets/c60_banner.png'
import c60TopBannerMd from '../../../Assets/c60_page_assets/c60_banner@2x.png'
import c60EndBannerSm from '../../../Assets/c60_page_assets/c60_last.png'
import c60EndBannerMd from '../../../Assets/c60_page_assets/c60_last@2x.png'

export const topBanner = {
  title: 'Gain concrete answers to improve your RMC business in 60 seconds.',
  subtitle:
    'Ready mix concrete producers use our software to identify savings opportunities across their entire business. Imagine what you could do with an extra $1 per cube.',
  moto: 'Start improving your RMC margins today.',
  alertNeutral:
    'We can share your contact details with the experts from C60 so you can learn more about it.',
  alertSuccess:
    'Thank you for your interest a C60 representative will be in touch with you within one working day.',
  alertFailed:
    "We experienced technical issues and couldn't process your request. Please try again later.",
  imageUrls: {md: c60TopBannerMd, sm: c60TopBannerSm}
}

export const midBanner = {
  title: 'Our co-inventors have packaged their expertise into C60 to help producers save',
  disclaimer:
    '* These are the savings opportunities identified by the expertise of our co-inventors. Opportunities identified will vary by ready-mix producer.',
  cards: [
    {
      strongNumbers: '$20M+',
      description: 'savings opportunities identified *'
    },
    {
      strongNumbers: '64 M',
      description: 'Cubes of ready mix concrete volume impacted'
    },
    {
      strongNumbers: '1,500+',
      description: 'RMC Plants connected'
    },
    {
      strongNumbers: '$1-$5+',
      description: 'Savings opportunities per cube discovered'
    },
    {
      strongNumbers: '100+',
      description: 'RMC businesses working with our co-inventors'
    }
  ]
}

export const endBanner = {
  ...topBanner,
  title: 'Easily connect all of your ready mix concrete systems',
  subtitle:
    'Integrate C60 with leading RMC software systems like Command Alkon, Marcotte, Jonel, Sysdyne, Stonemont, and more, to get insights and a holistic view of your entire business, in one place.',
  imageUrls: {md: c60EndBannerMd, sm: c60EndBannerSm}
}

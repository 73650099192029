import React, {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import MultiSelectDropdown from '../../..//Molecules/MultiSelectDropdown'
import {setFilter} from '../Order.action'
import {useAllFeaturesOrderItems} from '../Order.utils'

import {status} from './constants'
import {PageNames} from '../../../common/constants'
import {filterType, useTrackFilterEvents} from '../../../TrackEvents/hubFilterEvents'

const OrderStatusFilter: React.FC = () => {
  const {isFilteringCancelledOrders} = useAllFeaturesOrderItems()
  const filteredStatuses = useRef(
    isFilteringCancelledOrders
      ? Object.keys(status)
      : Object.keys(status).filter((key) => key !== 'cancelled')
  )

  const {t} = useTranslation()
  const [selectedOptions, setOptionsList] = useState<string[]>(filteredStatuses.current || [])
  const dispatch = useDispatch()
  const {trackFilterEvents} = useTrackFilterEvents()

  const getOrderStatusFilters = (selectedList) => {
    const isListEmptyOrFull =
      selectedList.length === 0 || selectedList.length === filteredStatuses.current.length

    const filterEventData = {
      filterType: filterType.ORDER_STATUS,
      page: PageNames.ORDERS,
      orderStatus: isListEmptyOrFull ? [] : selectedList,
      cleared: isListEmptyOrFull
    }
    trackFilterEvents(filterEventData)

    // check if old and new are same, if same then return, else continue
    const isListUpdated =
      selectedOptions.length === selectedList.length &&
      selectedOptions.every((value, index) => value === selectedList[index])

    if (isListUpdated) return
    setOptionsList(selectedList)
    let selectedStatuses: string[] = []
    selectedList.map((orderStatusKey: string) => {
      selectedStatuses = [...selectedStatuses, ...status[orderStatusKey]]
      return
    })
    dispatch(setFilter('orderStatus', selectedStatuses.toString()))
  }

  return (
    <MultiSelectDropdown
      items={filteredStatuses.current}
      selectedOptionsList={selectedOptions}
      onClose={getOrderStatusFilters}
      dataTestId="order-status-filter-multiselect-dropdown"
      multiSelectLabel={t('order.orderStatusFilter.orderStatusFilterLabel')}
      selectAllLabel={t('filterMenu.allStatus')}
      translationItemPrefix="order.orderStatusFilter.status"
    />
  )
}

export default OrderStatusFilter

/* eslint-disable prettier/prettier */
import {Typography} from '@material-ui/core'


interface CanceledByProps {
  canceledByName: string | undefined
  canceledByEmail: string | undefined
}

export const CanceledBy: React.FC<CanceledByProps> = ({canceledByName, canceledByEmail}) => {

  return (
    <>
      <Typography color="textPrimary" variant="subtitle1">
        {canceledByName}
      </Typography>
      <Typography color="textPrimary" variant="body2">
        {canceledByEmail}
      </Typography>
    </>
  )
}

import {Invoice} from '../Finance/Invoices/Invoice.types'

export const FETCH_LATEST_INVOICES_PENDING = 'FETCH_LATEST_INVOICES_PENDING'
export const FETCH_LATEST_INVOICES_SUCCESS = 'FETCH_LATEST_INVOICES_SUCCESS'

interface FetchLatestInvoicesPending {
  type: typeof FETCH_LATEST_INVOICES_PENDING
}
interface FetchLatestInvoicesSuccess {
  type: typeof FETCH_LATEST_INVOICES_SUCCESS
  payload: Invoice[]
  receivedAt: string
}

export type OverviewActionTypes = FetchLatestInvoicesPending | FetchLatestInvoicesSuccess

export interface OverviewPageState {
  isFetching: boolean
  // TODO: change this once we need to handle more than one widget
  items: Invoice[]
  dateFilter: {startDate: any; endDate: any}
  sortOrder: {key: string; asc: boolean}
}

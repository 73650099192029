import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {TFunction} from 'i18next'
import React from 'react'

import TimezoneSvg from '../../../Assets/timezone.svg'
import {Order, ShippingType} from '../Order.types'

export const TimezoneWarning: React.FC<{
  t: TFunction
  order: Order
  userOffset: number
  eventLocationOffset?: number
}> = ({t, order, userOffset, eventLocationOffset}) => {
  if (eventLocationOffset === undefined || userOffset === eventLocationOffset) return null

  const isDelivery = order.shippingType === ShippingType.Deliver
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      mt={0.4}
      mb={0.4}
      style={{gap: '5px'}}
      fontSize={12}
      data-test-id={`order-timezone-warning-${order.orderId}`}
    >
      <Typography variant="caption" style={{color: '#EE8B00', fontWeight: 500}}>
        <img src={TimezoneSvg} height="12px" alt="timezone" style={{marginBottom: -2}} />{' '}
        {isDelivery ? t('order.destinationTimeZone') : t('order.pickupTimeZone')}
      </Typography>
    </Box>
  )
}

import overlay_1 from '../../../Assets/c60_page_assets/c60_alliance.png'
import overlay_3 from '../../../Assets/c60_page_assets/c60_beton.png'
import overlay_2 from '../../../Assets/c60_page_assets/c60_calcestruzzi.png'
import c60_end_1_sm from '../../../Assets/c60_page_assets/c60_end_1.png'
import c60_end_1_md from '../../../Assets/c60_page_assets/c60_end_1@2x.png'
import c60_end_2_sm from '../../../Assets/c60_page_assets/c60_end_2.png'
import c60_end_2_md from '../../../Assets/c60_page_assets/c60_end_2@2x.png'
import c60_end_3_sm from '../../../Assets/c60_page_assets/c60_end_3.png'
import c60_end_3_md from '../../../Assets/c60_page_assets/c60_end_3@2x.png'
import c60_end_4_sm from '../../../Assets/c60_page_assets/c60_end_4.png'
import c60_end_4_md from '../../../Assets/c60_page_assets/c60_end_4@2x.png'
import c60_mid_1_sm from '../../../Assets/c60_page_assets/c60_mid_1.png'
import c60_mid_1_md from '../../../Assets/c60_page_assets/c60_mid_1@2x.png'
import c60_mid_2_sm from '../../../Assets/c60_page_assets/c60_mid_2.png'
import c60_mid_2_md from '../../../Assets/c60_page_assets/c60_mid_2@2x.png'
import c60_mid_3_sm from '../../../Assets/c60_page_assets/c60_mid_3.png'
import c60_mid_3_md from '../../../Assets/c60_page_assets/c60_mid_3@2x.png'
import c60_mid_4_sm from '../../../Assets/c60_page_assets/c60_mid_4.png'
import c60_mid_4_md from '../../../Assets/c60_page_assets/c60_mid_4@2x.png'
import overlay_4 from '../../../Assets/c60_page_assets/c60_sagrex.png'
import type {TabCardType} from '../../../Molecules/VerticalTabsShowcase/types'

export const c60Colors = {
  orange: '#D15C02',
  blue: '#0080D6',
  purple: '#BA3CAE',
  lightblue: '#009D93'
}

export const midTabs: TabCardType[] = [
  {
    tabId: 'mid-1',
    title: 'Immediately see the top saving opportunities in 60 Seconds',
    subtitle:
      'Producers want answers not more data - C60 immediately shows you the most valuable opportunities in your business.',
    imageUrls: {md: c60_mid_1_md, sm: c60_mid_1_sm}
  },
  {
    tabId: 'mid-2',
    title: 'Take action on what matters most',
    subtitle:
      'Drill on opportunities and choose the products, mixes, trucks, or customers to act on. Take actions and save money.',
    imageUrls: {md: c60_mid_2_md, sm: c60_mid_2_sm}
  },
  {
    tabId: 'mid-3',
    title: 'Track your savings',
    subtitle:
      "Busy GMs can track the performance improvement across their business and see the dollars they're saving.",
    imageUrls: {md: c60_mid_3_md, sm: c60_mid_3_sm}
  },
  {
    tabId: 'mid-4',
    title: 'Dashboards across your business',
    subtitle: 'Rich insights across materials, productions, delivery, and sales.',
    imageUrls: {md: c60_mid_4_md, sm: c60_mid_4_sm}
  }
]

export const endTabs: TabCardType[] = [
  {
    tabId: 'end-1',
    title: 'Optimize the use of your raw materials',
    subtitle:
      'C60 helps lower raw material costs by identifying inaccurate mix yields, highlighting over and under-performing mixes for cement optimization and risk-management, and monitoring the variability in plant and mix performance to allow for improved production consistency and mix optimization.',
    variant: c60Colors.orange,
    variantTitle: 'Materials',
    overlayUrl: overlay_1,
    overlayTitle: 'Alliance saves an average of HKD $250,000 annually',
    overlayText: 'by reducing recurring instances of over-yielding',
    imageUrls: {md: c60_end_1_md, sm: c60_end_1_sm}
  },
  {
    tabId: 'end-2',
    title: 'Optimize your batch plants performance',
    subtitle:
      'Learn which plants are driving up your operational costs the most. Quickly gain the actionable insights you need to determine which plants have the most batching errors, the highest variability of batching accuracy, the lowest operational capacity, and more.',
    variant: c60Colors.blue,
    variantTitle: 'Production',
    overlayUrl: overlay_2,
    overlayTitle: 'Calcestruzzi saves an average of $10,000 per month',
    overlayText: 'by reducing over batching instances by 50%',
    imageUrls: {md: c60_end_2_md, sm: c60_end_2_sm}
  },
  {
    tabId: 'end-3',
    title: 'Optimize your delivery performance',
    subtitle:
      'Keep track and gain actionable insights to improve your in-house and spot RMC truck utilization, optimize the load capacity of different trucks, the status accuracy of your fleet and more.',
    variant: c60Colors.purple,
    variantTitle: 'Delivery',
    overlayUrl: overlay_3,
    overlayTitle: 'Heidelberger Beton saves an average of €120,000 annually',
    overlayText: 'by identifying & retraining their worst performing drivers',
    imageUrls: {md: c60_end_3_md, sm: c60_end_3_sm}
  },
  {
    tabId: 'end-4',
    title: 'Optimize your revenue strategy',
    subtitle:
      'Learn which plants are driving up your operational costs the most. Quickly gain the actionable insights you need to determine which plants have the most batching errors, the highest variability of batching accuracy, the lowest operational capacity, and more.',
    variant: c60Colors.lightblue,
    variantTitle: 'Sales',
    overlayUrl: overlay_4,
    overlayTitle: 'SAGREX saves an average of  €150,000 annually',
    overlayText:
      'by identifying their customers with the highest cost of service and helping them negotiate more profitable agreements',
    imageUrls: {md: c60_end_4_md, sm: c60_end_4_sm}
  }
]

import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {selectCustomers} from '../../../Organisms/Customers'
import {CustomerStateType} from '../../../Organisms/Customers/Action.types'
import {AppState} from '../../../Root.store'
import {showOngoingOrders, showUpcomingOrders} from '../../Order/Order.action'

const useStyles = makeStyles(() => ({
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export const OrderByStatus: React.FC = () => {
  const dispatch = useDispatch()
  const customerState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const selectedCustomer = customerState.selectedCustomer
  const classes = useStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  if (!selectedCustomer) {
    return null
  }
  return (
    <div className={classes.alignRight}>
      <Button
        color="inherit"
        onClick={() => dispatch(showUpcomingOrders(language, selectedCustomer))}
      >
        {t('overview.upcoming')}
      </Button>
      <Button
        color="primary"
        style={{marginLeft: 16}}
        onClick={() => dispatch(showOngoingOrders(language, selectedCustomer))}
      >
        {t('overview.ongoing')}
      </Button>
    </div>
  )
}

import {useFeaturesState} from '../../../Organisms/Features'
import {Order, ShippingType} from '../Order.types'
import {getIsCEM} from '../Order.utils'

export const showProgressBar = (
  o: Order,
  isCountryProgressEnabled: boolean,
  showOrderProgressBarCollect: boolean
) => {
  const isNotCEM = !getIsCEM(o)
  const isShippingTypeValid = showOrderProgressBarCollect || o.shippingType !== ShippingType.Collect

  return isNotCEM && isShippingTypeValid && isCountryProgressEnabled
}

export const useProgressBarVisibility = (order: Order) => {
  const {getFeature} = useFeaturesState()
  const isCountryProgressEnabled = getFeature('OrderProgressBar')
  const showOrderProgressBarCollect = getFeature('OrderProgressBarCollect')

  return showProgressBar(order, isCountryProgressEnabled, showOrderProgressBarCollect)
}

import {MOMENTJS_DATE_FORMAT} from '@hconnect/uikit'
import {AxiosResponse} from 'axios'
import {push} from 'connected-react-router'
import moment from 'moment'

import {ROUTE, InvoiceRoute} from '../Finance'
import {AccountParams} from '../Finance/Invoices/hooks'
import {setDateFilter} from '../Finance/Invoices/Invoice.filters'
import {Invoice} from '../Finance/Invoices/Invoice.types'

import {
  FETCH_LATEST_INVOICES_PENDING,
  FETCH_LATEST_INVOICES_SUCCESS,
  OverviewActionTypes
} from './Action.types'

export const showLatestInvoices = (dateRange: number) => (dispatch) => {
  dispatch(
    setDateFilter({
      startDate: moment().subtract(dateRange, 'days').format(MOMENTJS_DATE_FORMAT),
      endDate: moment().format(MOMENTJS_DATE_FORMAT)
    })
  )
  dispatch(push(`${ROUTE}${InvoiceRoute}`))
}

const fetchLatestInvoicesPending = (): OverviewActionTypes => ({
  type: FETCH_LATEST_INVOICES_PENDING
})

const fetchLatestInvoicesSuccess = (payload: Invoice[]): OverviewActionTypes => ({
  type: FETCH_LATEST_INVOICES_SUCCESS,
  payload,
  receivedAt: moment().format(MOMENTJS_DATE_FORMAT)
})

export const fetchLatestInvoices =
  (baseParams: AccountParams, dateRange) =>
  async (dispatch, getState, {api}) => {
    // inform UI that we are going to load data now
    dispatch(fetchLatestInvoicesPending())

    try {
      const params = {
        startDate: moment().subtract(dateRange, 'days').format(MOMENTJS_DATE_FORMAT),
        endDate: moment().format(MOMENTJS_DATE_FORMAT),
        sortedBy: 'invoiceDate desc'
      }

      const response: AxiosResponse<Invoice[]> = await api.get('/invoices', {
        params: {
          ...baseParams,
          ...params
        }
      })
      dispatch(fetchLatestInvoicesSuccess(response.data))
    } catch (e) {
      console.error('Error occurred while fetching invoices', e)
    }
  }

import {NotificationSettings} from '../../../OrderIntake/declarations/types'
import {NotificationSettingsValues} from '../types/NotificationSettingsValues'

export const getDefaultValues = (
  notificationSettings: NotificationSettings[] | undefined
): NotificationSettingsValues | undefined => {
  if (!notificationSettings) return undefined

  return {
    receiveProofOfDeliveryUpdates:
      notificationSettings.find((setting) => (setting.option === 'immediate' && setting.type === 'ePod'))?.isActive ?? false
  }
}

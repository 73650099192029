/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {ongoingDates, upcomingDates} from '@hconnect/uikit'
import {push} from 'connected-react-router'
import {isEmpty} from 'lodash'

import {setGlobalCustomerFilter} from '../../Organisms/Customers'
import {Customer} from '../../Organisms/Customers/Customers.types'
import {
  clearDefaultFiltersAbstract,
  clearFiltersAbstract,
  removeDefaultFilterAbstract,
  removeFilterAbstract,
  setDefaultFilterAbstract,
  setFilterAbstract
} from '../../Organisms/Filters'
import {
  ORDERS,
  clear as clearOrders,
  fetchDeliveryByCustomerDeliveryNumber,
  fetchDeliveryByDeliveryNumber,
  fetchOrders as fetchOrdersAbstract,
  fetchOrdersFailure,
  fetchOrdersRequest,
  selectDeliveriesByCustomerDeliveryNumber,
  selectDeliveriesByDeliveryNumber,
  selectOrders
} from '../../Organisms/Orders'
import {TestStatus} from '../../Organisms/TestStatus'
import {AppState} from '../../Root.store'
import {Delivery} from '../Finance/Invoices/Delivery.types'

import {OrderPageActionTypes} from './Action.types'
import {LookupEntityParam, LookupSelectItems, OrderViews} from './Order.enum'
import {
  selectCustomerOrderFilter,
  selectDateFilter,
  selectLookupFilter,
  selectProjectFilter,
  selectSiteFilter,
  selectTestStatusFilter,
  setDateFilter
} from './Order.filters'
import {SearchOrdersQueryParameters} from './Order.types'

import {ROUTE as OrdersRoute} from '.'

export const setFilter = (filterName, filterValue) =>
  setFilterAbstract(ORDERS, filterName, filterValue)
export const setDefaultFilter = (filterName, filterValue) =>
  setDefaultFilterAbstract(ORDERS, filterName, filterValue)
export const removeFilter = (filterName) => removeFilterAbstract(ORDERS, filterName)
export const removeDefaultFilter = (filterName) => removeDefaultFilterAbstract(ORDERS, filterName)
export const clearFilters = () => clearFiltersAbstract(ORDERS)
export const clearDefaultFilters = () => clearDefaultFiltersAbstract(ORDERS)

export const ORDERS_SWITCH_VIEW = 'ORDERS/SWITCH_VIEW'
export const ORDERS_CLEAR_VIEW = 'ORDERS/CLEAR_VIEW'

export const switchView = ({
  view,
  clearPrevView
}: {
  view: OrderViews
  clearPrevView?: any
}): OrderPageActionTypes => ({
  type: ORDERS_SWITCH_VIEW,
  view,
  clearPrevView
})

export const clearCurrentView = (): OrderPageActionTypes => ({
  type: ORDERS_CLEAR_VIEW
})

export const showUpcomingOrders = (language: string, customer: Customer) => (dispatch) => {
  const [startDate, endDate] = upcomingDates(language)
  dispatch(clearOrders())
  dispatch(setGlobalCustomerFilter(customer))
  dispatch(
    setDateFilter({
      startDate: startDate.toDate().toISOString(),
      endDate: endDate.toDate().toISOString()
    })
  )
  dispatch(push(OrdersRoute))
}

export const showOngoingOrders = (language: string, customer: Customer) => (dispatch) => {
  const [startDate, endDate] = ongoingDates(language)
  dispatch(clearOrders())
  dispatch(setGlobalCustomerFilter(customer))
  dispatch(
    setDateFilter({
      startDate: startDate.toDate().toISOString(),
      endDate: endDate.toDate().toISOString()
    })
  )
  dispatch(push(OrdersRoute))
}

// eslint-disable-next-line max-statements, complexity
export const fetchOrders =
  (
    isGroupedDeliveries: boolean,
    isFilteringOutCancelledDeliveries = false,
    isFilteringCancelledOrders = false,
    isPumpServiceIncluded = false,
    isServiceOrderIncluded = false,
    includeDeliveryInstructions = true,
    expandToOrderDocuments = false,
    useHaulierView = false,
    siteId?: string,
    analyticsId?: string
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState, {api}) => {
    const state: AppState = getState()
    const {customerId} = selectCustomerOrderFilter(state) || {}
    const {orderStatus, orgUnitIds} = selectOrders(state).filters
    const expand = [...(expandToOrderDocuments ? ['orderDocuments'] : [])].join(',')
    let params: SearchOrdersQueryParameters = {
      ...(useHaulierView ? {haulierId: customerId} : {customerId: customerId}),
      expand,
      ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
      ...(isPumpServiceIncluded ? {includePumpService: true} : {}),
      ...(isServiceOrderIncluded ? {includeServiceOrder: true} : {}),
      ...(includeDeliveryInstructions ? {includeDeliveryInstructions: true} : {}),
      ...(orderStatus && {orderStatus}),
      ...(orgUnitIds && {orgUnitIds}),
      ...(isFilteringCancelledOrders
        ? {excludeCancelledOrders: false}
        : {excludeCancelledOrders: true})
    }
    const lookup = selectLookupFilter(state)

    if (lookup) {
      const {entity, value} = lookup
      // If entity is TICKET then we first want to get OrderNumber from deliveries?deliveryNumber=ticket
      // After that set params to orderId
      if ((entity === LookupSelectItems.TICKET || entity === LookupSelectItems.BOL) && customerId) {
        let deliveries: Delivery[] = []
        if (entity === LookupSelectItems.TICKET) {
          // start loading indicator for orders-view
          dispatch(fetchOrdersRequest({deliveryNumber: value, customerId}))
          // get deliveries for deliveryNumber
          await dispatch(
            fetchDeliveryByDeliveryNumber(value, customerId, isPumpServiceIncluded, isFilteringOutCancelledDeliveries, useHaulierView, () =>
              trackEvent('hubLookupFailed', {
                product: 'hub',
                lookupCategory: entity,
                lookupValue: value,
                lookupSource: 'orders',
                customerId,
                analyticsId
              })
            )
          )

          const latestState: AppState = getState()
          // select by customDeliveryNumber
          deliveries = selectDeliveriesByDeliveryNumber(latestState, value, customerId, useHaulierView)
        }
        if (entity === LookupSelectItems.BOL) {
          // start loading indicator for orders-view
          dispatch(fetchOrdersRequest({customerDeliveryNumber: value, customerId}))
          await dispatch(
            fetchDeliveryByCustomerDeliveryNumber(value, customerId, isPumpServiceIncluded, isFilteringOutCancelledDeliveries, () =>
              trackEvent('hubLookupFailed', {
                product: 'hub',
                lookupCategory: entity,
                lookupValue: value,
                lookupSource: 'orders',
                customerId,
                analyticsId
              })
            )
          )

          const latestState = getState()
          // select by customDeliveryNumber
          deliveries = selectDeliveriesByCustomerDeliveryNumber(latestState, value, customerId)
        }
        if (deliveries && deliveries.length > 0) {
          dispatch(clearCurrentView())
          deliveries.forEach((delivery: Delivery) => {
            const {orderId} = delivery
            if (orderId) {
              params = {
                ...params,
                [LookupEntityParam[LookupSelectItems.TICKET]]: orderId,
                skip: 0
              }
              dispatch(fetchOrdersAbstract(params, isGroupedDeliveries))
            }
          })
          return
        }
        trackEvent('hubLookupFailed', {
          product: 'hub',
          lookupCategory: entity,
          lookupValue: value,
          lookupSource: 'orders',
          customerId,
          analyticsId
        })
        dispatch(fetchOrdersFailure('entity not found'))
        return
      }
      if (customerId) {
        const {sortOrder, skip, limit} = selectOrders(state)
        params = {
          ...params,
          sortedBy: `${sortOrder.key} ${sortOrder.asc ? 'asc' : 'desc'}`,
          [LookupEntityParam[entity]]: value,
          skip,
          limit: limit + 1
        }
      }
    } else {
      const {sortOrder, skip, limit} = selectOrders(state)

      const {startDate, endDate} = selectDateFilter(state) || {}
      const site = siteId || selectSiteFilter(state)?.siteId
      const {projectId} = selectProjectFilter(state) || {}
      const {testStatus} = selectTestStatusFilter(state) || {}
      const isTestBooked = !!(testStatus && testStatus === TestStatus.Booked)
      params = {
        ...params,
        sortedBy: `${sortOrder.key} ${sortOrder.asc ? 'asc' : 'desc'}`,
        startDate,
        endDate,
        siteId: site,
        projectId,
        skip,
        limit: limit + 1 // download one more to decide whether to show "Load more" button
      }
      if (testStatus) {
        params.isTestBooked = isTestBooked
      }
    }
    !isEmpty(params) &&
      dispatch(fetchOrdersAbstract(params, isGroupedDeliveries, lookup, analyticsId))
  }

import {Typography} from '@hconnect/uikit'
import {styled} from '@mui/material'

interface ResponsiveComponentType {
  smallScreen?: boolean
}
export const BigHeader = styled(Typography)<ResponsiveComponentType>(({smallScreen}) => ({
  fontSize: smallScreen ? 24 : 28,
  fontWeight: 600,
  lineHeight: smallScreen ? '28px' : '34px'
}))

export const StrongNumber = styled(Typography)<ResponsiveComponentType>(({smallScreen}) => ({
  fontSize: smallScreen ? 26 : 40,
  fontWeight: 600,
  lineHeight: smallScreen ? '33px' : '50px',
  color: '#00274D'
}))

export const StrongNumberDescription = styled(Typography)<ResponsiveComponentType>(
  ({smallScreen}) => ({
    fontSize: smallScreen ? 10 : 16,
    fontWeight: 400,
    lineHeight: smallScreen ? '14px' : '22px',
    color: '#54708C'
  })
)

export const DisclaimerText = styled(Typography)<ResponsiveComponentType>(({smallScreen}) => ({
  fontSize: smallScreen ? 8 : 12,
  lineHeight: smallScreen ? '12px' : '18px',
  fontStyle: 'italic',
  fontWeight: 400
}))

export const BottomMotoText = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '40px'
})

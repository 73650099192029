import {dateFormatter} from '@hconnect/uikit'
import {LinearProgress} from '@material-ui/core'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import currencyFormatter from 'currency-formatter'
import get from 'lodash/get'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

import {useFeaturesState} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'
import {useAccountParams} from '../../Finance/Invoices/hooks'
import {Invoice} from '../../Finance/Invoices/Invoice.types'
import {fetchLatestInvoices} from '../Overview.action'

const useStyles = makeStyles((theme) => ({
  table: {
    margin: `0 ${theme.spacing(2)}px`,
    '& td': {
      paddingRight: theme.spacing(4)
    },
    '& td:last-child': {
      paddingRight: 0
    }
  },
  loadingIndicator: {
    width: '100%',
    marginBottom: '22px'
  },

  infoText: {
    margin: 0,
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400, // regular
    color: 'rgba(255,255,255, 0.96)'
  },
  invoice: {
    cursor: 'pointer'
    // border: '1px solid transparent',
    // '&:hover td': {
    //   backgroundColor: 'rgba(255, 255, 255, 0.16)',
    //   border: '1px solid rgba(255, 255, 255, 0.16)'
    // }
  }
}))

const HCLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(41, 170, 255, 0.4)'
  },
  barColorPrimary: {
    backgroundColor: 'rgb(41, 170, 255)'
  }
})(LinearProgress)

interface InvoiceOverviewTableType {
  history: any
  dateRange: number
  displayDateRange: number
}

const InvoiceOverviewTable: React.FC<InvoiceOverviewTableType & RouteComponentProps> = ({
  history,
  dateRange,
  displayDateRange
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()

  const classes = useStyles()
  const dispatch = useDispatch()
  const invoices = useSelector<AppState, Invoice[]>((state) =>
    state.overview.invoice.items.slice(0, 5)
  )
  const isFetching = useSelector<AppState, boolean>((state) => state.overview.invoice.isFetching)
  const customerId = useSelector<AppState, string | undefined>((state) =>
    get(state, 'customers.selectedCustomer.customerId')
  )

  const isInvoiceDetailsDisabled = (invoice: Invoice) =>
    getResolutionByNameAndConstraint('InvoiceDetails', normalized, 'connectorId').disabled.includes(
      invoice.invoiceId.split('.')[0]
    )

  const invoicesParams = useAccountParams()
  useEffect(() => {
    if (!invoicesParams) {
      return
    }

    dispatch(fetchLatestInvoices(invoicesParams, dateRange))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesParams])

  let content
  if (!isFetching && get(invoices, 'length', 0) === 0) {
    content = (
      <div data-test-id="last-invoices-empty" className={classes.infoText}>
        {t('overview.noInvoices', {days: displayDateRange})}
      </div>
    )
  } else {
    const invoiceNumber = (invoice: Invoice) =>
      getFeature('CustomerInvoiceNumber') ? invoice.customerInvoiceNumber : invoice.invoiceNumber

    content = (
      <table data-test-id="last-invoices-table" className={classes.table}>
        <tbody>
          {invoices.map((invoice) => (
            <tr
              className={!isInvoiceDetailsDisabled(invoice) ? classes.invoice : ''}
              key={invoice.invoiceId}
              onClick={
                !isInvoiceDetailsDisabled(invoice)
                  ? () => history.push(`invoices/${invoice.invoiceId}?customerId=${customerId}`)
                  : undefined
              }
            >
              <td>{invoice.invoiceDate ? dateFormatter(invoice.invoiceDate, language) : ''}</td>
              <td>{invoiceNumber(invoice)}</td>
              <td>{t(`invoice.statuses.${invoice.status}`).toLocaleLowerCase()}</td>
              <td>
                {currencyFormatter.format(invoice.invoiceGrossValue, {
                  code: invoice.invoiceCurrency,
                  locale: language
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <div style={{overflowX: 'auto'}}>
      {isFetching ? <HCLinearProgress className={classes.loadingIndicator} /> : null}
      {content}
    </div>
  )
}

export default withRouter(InvoiceOverviewTable)

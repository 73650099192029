import {selectOrder} from '../../Organisms/Orders'
import {AppState} from '../../Root.store'

export const selectView = (state: AppState) => state.order.view

export const selectViewCurrent = (state: AppState) => selectView(state).currentView

// TODO type view
export const selectViewOrders = (state: AppState, view: string) =>
  selectView(state)[view].map((orderId: string) => selectOrder(state, orderId))

export const selectViewCurrentOrders = (state: AppState) =>
  selectViewOrders(state, selectViewCurrent(state))

import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

export const useNotificationSettingsStyles = makeStyles((theme: Theme) => ({
  fullHeightContainer: {
    height: 'calc(100vh - 200px)',
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`
    },
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  isLoading: {
    pointerEvents: 'none',
    opacity: 0.8
  },
  textCenterAlignment: {
    textAlign: 'center'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
     minHeight: '500px',
    }
  }
}))

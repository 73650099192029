/* eslint-disable complexity */

import {useTranslation} from 'react-i18next'
import {useNotification} from '@hconnect/uikit'
import EmailNotification from '../../../Assets/notificationSettings/emailNotification.svg'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useNotificationSettingsStyles} from '../hooks/useNotificationSettingsStyles'
import {NotificationSettingsValues} from '../types/NotificationSettingsValues'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography
} from '@mui/material'
import classNames from 'classnames'
import {useGetNotificationSettings} from '../../../../src/common/react-query/hooks/queries/useGetNotificationSettings'
import {usePostNotificationSettings} from '../../../../src/common/react-query/hooks/mutations/usePostNotificationSettings'
import {useEffect, useMemo} from 'react'
import {getDefaultValues} from '../utils/get-default-values.util'
import {mapFormValuesToRequestData} from '../utils/map-form-values-to-request-data.util'
import {trackEvent} from '../../../common/analytics'
import {AxiosError} from 'axios'

export const NotificationSettingsForm = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const c = useNotificationSettingsStyles()

  const {data: notificationSettings, isLoading: isNotificationSettingsLoading} =
    useGetNotificationSettings()
  const {mutate} = usePostNotificationSettings()

  const defaultValues = useMemo(
    (): NotificationSettingsValues | undefined => getDefaultValues(notificationSettings),
    [notificationSettings]
  )

  const methods = useForm<NotificationSettingsValues>({
    mode: 'onChange',
    defaultValues
  })

  const receiveProofOfDeliveryUpdates = methods.watch('receiveProofOfDeliveryUpdates')

  const saveNotificationSettings = async () => {
    const notificationSettings = mapFormValuesToRequestData(methods.getValues())

    mutate(
      {notificationSettings: notificationSettings},
      {
        onSuccess: () => {
          trackEvent('hubNotificationSettingsWritten', {
            type: notificationSettings.type,
            option: notificationSettings.option,
            isActive: notificationSettings.isActive
          })
        },
        onError: (e) => {
          notify('error', t('orderIntake.notificationSettings.saveNotificationSettingsError'))
          trackEvent('hubNotificationSettingsError', {
            errorCode: (e as AxiosError).response?.status,
          })
        }
      }
    )
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    saveNotificationSettings()
  }, [receiveProofOfDeliveryUpdates])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        marginTop={0}
        className={classNames(
          isNotificationSettingsLoading ? c.isLoading : '',
          c.fullHeightContainer
        )}
      >
        <Grid item xs={12} md={8}>
          <Paper elevation={4} sx={{height: '100%'}}>
            <Grid container className={c.container} data-test-id="notification-settings-container">
              <Box display="flex" flexDirection="column">
                {isNotificationSettingsLoading ? (
                  <Box display="flex" justifyContent="center" marginTop={2} marginBottom={4}>
                    <CircularProgress size={40} color="inherit" />
                  </Box>
                ) : null}
                <Box>
                  <Typography variant="h3">
                    {t('orderIntake.notificationSettings.emailNotifications')}
                  </Typography>
                  <Typography color="secondary" variant="body2" marginTop={1}>
                    {t('orderIntake.notificationSettings.emailNotificationsDescription')}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" marginTop={4}>
                  <Controller
                    control={methods.control}
                    name="receiveProofOfDeliveryUpdates"
                    render={({field}) => (
                      <>
                        <FormControlLabel
                          control={<Switch data-test-id="notification-settings-receive-e-pod" checked={field.value} onChange={field.onChange} />}
                          label={
                            <Box marginLeft={2}>
                              <Typography variant="body1" fontWeight="bold" color="textPrimary">
                                {t(
                                  'orderIntake.notificationSettings.receiveProofOfDeliveryUpdates.label'
                                )}
                              </Typography>
                              <Typography variant="body2" color="secondary">
                                {t(
                                  'orderIntake.notificationSettings.receiveProofOfDeliveryUpdates.caption'
                                )}
                              </Typography>
                            </Box>
                          }
                        />
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={4}>
            <Box
              className={classNames(c.container, c.imageContainer)}
              data-test-id="notification-settings-image-container"
            >
              <img src={EmailNotification} alt="" height="200x" />
              <Box mb={1}>
                <Typography variant="body2" color="secondary" className={c.textCenterAlignment}>
                  {t('orderIntake.notificationSettings.emailNotificationsInfo')}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

import classNames from 'classnames'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {BusinessLineTypes} from '../../../../common/types'
import {ShippingType} from '../../Order.types'

const useStyles = makeStyles()(() => ({
  detailField: {
    display: 'flex',
    flexDirection: 'column'
  },
  detailFieldLabel: {
    color: '#54708C',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 700
  },
  detailFieldValue: {
    color: '#00274D',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 600
  },
  detailFieldSubValue: {
    color: '#00274D',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 500
  },
  fullWidth: {
    gridColumn: '1 / -1'
  }
}))

interface DetailFieldProps {
  label: string
  value: string | React.ReactNode
  subValue?: string | React.ReactNode
  dataTestId?: string
  businessLine?: BusinessLineTypes
  showForBusinessLines?: BusinessLineTypes[]
  shippingType?: ShippingType
  showForShippingType?: ShippingType
  fullWidth?: boolean
}

export const DetailsField: React.FC<DetailFieldProps> = ({
  label,
  value,
  subValue,
  businessLine,
  showForBusinessLines,
  shippingType,
  showForShippingType,
  fullWidth = false,
  dataTestId
}) => {
  const {classes} = useStyles()

  if (!value) return null
  if (businessLine && showForBusinessLines && !showForBusinessLines.includes(businessLine))
    return null

  if (shippingType && showForShippingType && shippingType !== showForShippingType) return null

  return (
    <div className={classNames(classes.detailField, fullWidth && classes.fullWidth)}>
      <div className={classes.detailFieldLabel}>{label}</div>
      <div className={classes.detailFieldValue} data-test-id={dataTestId}>
        {value}
      </div>
      {subValue && <div className={classes.detailFieldSubValue}>{subValue}</div>}
    </div>
  )
}

import {dateFormatter, timeFormatter} from '@hconnect/uikit'
import {TFunction} from 'i18next'
import moment from 'moment'

// eslint-disable-next-line import/no-cycle
import {OrderChange, OrderChangedField} from '../Order.types'
import {getOrderReason, getUnloadingMethod} from '../Order.utils'

export const getOrderChangeText: (
  change: OrderChange,
  uom: string,
  t: TFunction,
  language: string
) => string = (change, uom, t, language) => {
  const mapping = getMapping(change.fieldDescription, t, language, uom)

  const changedField = t(`order.orderDetails.changes.${change.fieldDescription}`)
  const oldValue = mapping(change.oldValue)
  const newValue = mapping(change.newValue)
  const changedBy = t(`order.orderDetails.changes.${change.changedBy}`)

  return t('order.orderDetails.changes.pattern', {
    changedField,
    oldValue,
    newValue,
    changedBy
  })
}

const getMapping: (
  fieldDescription: keyof typeof OrderChangedField,
  t: TFunction,
  language: string,
  uom: string
) => (value: string) => string = (fieldDescription, t, language, uom) => {
  const dateFormat = 'YYYYMMDD'
  const hourFormat = 'HHmmSS'
  switch (fieldDescription) {
    case OrderChangedField.UnloadingMethod:
      return (value) => getUnloadingMethod(t, value) || ''
    case OrderChangedField.UnloadingSpeed:
      return (value) => parseFloat(value).toString()
    case OrderChangedField.EarliestLoadDateTime:
      return (value) => dateFormatter(moment(value, dateFormat), language) || ''
    case OrderChangedField.FirstLoadDateTime:
      return (value) => timeFormatter(moment(value, hourFormat), language)
    case OrderChangedField.ArrivalSpacing:
      return (value) => {
        const span = moment(value, hourFormat)
        const minutes = span.hours() * 60 + span.minutes()
        return `${minutes} ${t('order.orderDetails.minutes')}`
      }
    case OrderChangedField.QuantityPerhour:
      return (value) => t('order.orderDetails.flowRatePerHour', {flowRate: parseFloat(value), uom})
    case OrderChangedField.Quantity:
      return (value) => `${parseFloat(value)} ${uom}`
    case OrderChangedField.UnloadingTime:
      return (value) => `${parseInt(value)} ${t('order.orderDetails.minutes')}`
    case OrderChangedField.OrderReason:
      return (value) => `'${getOrderReason(t, value) || ''}'`
    default:
      return (value) => value
  }
}

import {withRoute} from '@hconnect/uikit'

import {RequestForQuotePage} from './RequestForQuotePage'

export const ROUTE = '/requestForQuote'

export default withRoute({
  path: ROUTE,
  label: 'RequestForQuote',
  labelKey: 'requestForQuote.navigationLabel',
  order: 1000,
  featureToggleName: 'OrderIntakeRequestForQuote',
  excludeFeatureToggleName: 'UseHaulierView',
  routeProps: {exact: true}
})(RequestForQuotePage)

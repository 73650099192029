import React from 'react'
import {useTranslation} from 'react-i18next'

import {FieldDetails} from '../../../Molecules/FieldDetails'
import {Features} from '../../../Organisms/Features'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {Order, ShippingType} from '../Order.types'
import {BusinessLineType} from '../../../common/types'

interface LoadingNumberValueType {
  order: Order
  deliveries?: Delivery[]
}

export const LoadingNumberValue: React.FC<LoadingNumberValueType> = ({order, deliveries}) => {
  const {t} = useTranslation()

  const {businessLine, shippingType} = order

  let loadingNumberValue: string | undefined
  if (businessLine === BusinessLineType.AGG) {
    loadingNumberValue = order?.loadingNumber
  } else if (businessLine === BusinessLineType.CEM && deliveries?.length) {
    loadingNumberValue = deliveries[0].loadingNumber
  }

  if (!loadingNumberValue) return null

  return (
    <Features name="OrderDetailsPinCode">
      <FieldDetails
        dataTestId="loading-number"
        label={t('order.orderDetails.loadingNumber')}
        value={loadingNumberValue}
        businessLine={businessLine}
        showForBusinesslines={[BusinessLineType.CEM, BusinessLineType.AGG]}
        shippingType={shippingType}
        showForShippingType={ShippingType.Collect}
        gutter
      />
    </Features>
  )
}

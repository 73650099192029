import {Skeleton, Theme} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {Order} from '../../Order.types'

import {ContactDetailsField} from './ContactDetailsField'
import {MainOrderDetails} from './MainOrderDetails'

interface OrderDetailsProps {
  order?: Order
  deliveries?: Delivery[]
  isFetching: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  },
  contact: {
    borderLeft: '1px solid #E8ECF0',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      borderLeft: 0,
      paddingLeft: 0
    }
  }
}))

export const OrderDetails: React.FC<OrderDetailsProps> = ({order, deliveries, isFetching}) => {
  const {classes} = useStyles()

  if (isFetching) return <Skeleton variant="rectangular" height={200} />

  return (
    <div className={classes.container}>
      <MainOrderDetails order={order} deliveries={deliveries} />
      {order?.contactInformation && (
        <div className={classes.contact}>
          <ContactDetailsField siteContact={order?.contactInformation} />
        </div>
      )}
    </div>
  )
}

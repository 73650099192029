import {User} from '@hconnect/apiclient'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Link} from '@mui/material'
import {TFunction} from 'i18next'
import {useDispatch, useSelector} from 'react-redux'

import {AppState} from '../../../Root.store'
import {Order} from '../Order.types'
import {downloadOrderConfirmation, getOrderConfirmationLink} from '../Order.utils'

interface OrderNumberProps {
  order: Order
  includeItemNumber?: boolean
  entryPoint: string
  t: TFunction
}
export const OrderNumber: React.FC<OrderNumberProps> = ({
  order,
  includeItemNumber,
  entryPoint,
  t
}) => {
  const {orderNumber} = order
  const orderConfirmationPdf = getOrderConfirmationLink(order)
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const dispatch = useDispatch()
  const itemNumber =
    includeItemNumber && order.lineItems.length > 0 ? `- ${order.lineItems[0].itemNumber}` : ''
  const displayNumber = `${orderNumber} ${itemNumber}`
  if (!orderConfirmationPdf) return <>{displayNumber}</>
  return (
    <Link
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        downloadOrderConfirmation(order, user, entryPoint, t, dispatch)
      }}
      href="#"
    >
      <Box display="flex" gap={1}>
        <PictureAsPdfIcon
          data-test-id={`pdf-icon-${orderNumber}`}
          fontSize="small"
          color="primary"
        />
        {displayNumber}
      </Box>
    </Link>
  )
}

import {Box, Typography} from '@material-ui/core'
import React from 'react'

import {Customer} from '../../../Organisms/Customers/Customers.types'

interface SelectedCustomerType {
  customer: Customer | undefined
}

const SelectedCustomer: React.FC<SelectedCustomerType> = ({customer}) => (
  <Box display="flex" mt={2}>
    <Box mr={2}>
      <Typography variant="h2" color="textSecondary" data-test-id="customer-name">
        {customer?.customerName || ''}
      </Typography>
    </Box>
  </Box>
)

export default SelectedCustomer

import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {publicApi} from '../../App.global'

import {MaintenanceState} from './MaintenanceScreenProvider'

export const useMaintenanceMode = () => {
  return useQuery(
    ['maintenance-screen'],
    async () => {
      const origin = document.location.origin
      const result: AxiosResponse<MaintenanceState> = await publicApi.get(
        `${origin}/build/maintenance/hub.json`
      )
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      retry: false,
      staleTime: 0
    }
  )
}

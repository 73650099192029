import {selectCustomerFilterAbstract} from '../../Organisms/Customers'
import {createSelectFilter} from '../../Organisms/Filters'
import {selectOrders} from '../../Organisms/Orders'
import {selectProjectFilterAbstract} from '../../Organisms/Projects'
import {TestStatus} from '../../Organisms/TestStatus'
import {AppState} from '../../Root.store'

import {removeFilter, setDefaultFilter, setFilter} from './Order.action'

/*
Project
  */
export const selectProjectFilter = (state: AppState) =>
  selectProjectFilterAbstract(selectOrders(state))

/*
Site
 */
const SITEFILTER = 'site'
export const setSiteFilter = ({siteId}: {siteId: string}) => setFilter(SITEFILTER, {siteId})
export const setDefaultSiteFilter = ({siteId}: {siteId: string}) =>
  setDefaultFilter(SITEFILTER, {siteId})
export const removeSiteFilter = () => removeFilter(SITEFILTER)
const selectSiteFilterAbstract = createSelectFilter(SITEFILTER)
export const selectSiteFilter = (state: AppState) => selectSiteFilterAbstract(selectOrders(state))

/*
Customer a.k.a. Accounts
 */
export const selectCustomerOrderFilter = (state: AppState) =>
  selectCustomerFilterAbstract(selectOrders(state))

/*
Date
 */
const DATEFILTER = 'date'
export const setDateFilter = ({startDate, endDate}) => setFilter(DATEFILTER, {startDate, endDate})
export const setDefaultDateFilter = ({startDate, endDate}) =>
  setDefaultFilter(DATEFILTER, {startDate, endDate})
export const removeDateFilter = () => removeFilter(DATEFILTER)
const selectDateFilterAbstract = createSelectFilter(DATEFILTER)
export const selectDateFilter = (state: AppState) => selectDateFilterAbstract(selectOrders(state))

/*
Lookup
 */
const LOOKUPFILTER = 'lookup'
export const setLookupFilter = ({entity = null, value, label}) =>
  setFilter(LOOKUPFILTER, {
    entity,
    value,
    label
  })
export const removeLookupFilter = () => removeFilter(LOOKUPFILTER)
const selectLookupFilterAbstract = createSelectFilter(LOOKUPFILTER)
export const selectLookupFilter = (state: AppState) =>
  selectLookupFilterAbstract(selectOrders(state))

/*
Reset
 */
export const clearAllFilters = () => (dispatch) => {
  dispatch(removeSiteFilter())
  return dispatch(removeDateFilter())
}

/*
TestStatus
 */
const TEST_STATUS = 'testStatus'
export const setTestStatusFilter = ({testStatus}: {testStatus: TestStatus}) =>
  setFilter(TEST_STATUS, {testStatus})
export const setDefaultTestStatusFilter = ({testStatus}: {testStatus: TestStatus}) =>
  setDefaultFilter(TEST_STATUS, {testStatus})
export const removeTestStatusFilter = () => removeFilter(TEST_STATUS)
const selectTestStatusFilterAbstract = createSelectFilter(TEST_STATUS)
export const selectTestStatusFilter = (state: AppState) =>
  selectTestStatusFilterAbstract(selectOrders(state))

import {NotificationSettings} from '../../../OrderIntake/declarations/types'
import {NotificationSettingsValues} from '../types/NotificationSettingsValues'

export const mapFormValuesToRequestData = (
  data: NotificationSettingsValues
): NotificationSettings => ({
  type: 'ePod',
  option: 'immediate',
  isActive: data.receiveProofOfDeliveryUpdates
})

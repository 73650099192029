import {Subheader, TitleNav, Page, Content, HCThemeType} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useFeaturesState} from '../../Organisms/Features'

import {BulkCementCollectOrderForm} from '../../OrderIntake/BulkCement/Collect/BulkCementCollectOrderForm'
import {BulkCementCollectOrderLegacyForm} from '../../OrderIntake/BulkCement/Collect/BulkCementCollectOrderLegacyForm'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme: HCThemeType) => {
  return {
    pageStyle: {
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(4)}px`,
      },
      padding: 0,
    }
  }
})

const BulkCementCollectOrderIntake: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {getFeature} = useFeaturesState()
  const useLegacyDesign = getFeature('OrderIntakeUseLegacyDesign')

  const classes = useStyles()

  return (
    <Content>
      <Subheader>
        <TitleNav
          title={t('overview.orderIntakeCollectWidgetTitle')}
          onClick={() => history.goBack()}
        />
      </Subheader>
      <Page data-test-id="collect-order-intake-page" className={!useLegacyDesign ? classes.pageStyle : ""}>
        {useLegacyDesign ? <BulkCementCollectOrderLegacyForm /> :  <BulkCementCollectOrderForm />}
      </Page>
    </Content>
  )
}

export {BulkCementCollectOrderIntake}

/* eslint-disable complexity */
import {Box, Skeleton, Theme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {BusinessLineType} from '../../../../common/types'
import {DeliveryProgressBar} from '../../../../Molecules/DeliveryProgressBar'
import StatusIcon from '../../../../Molecules/StatusIcons'
import {Features, useFeaturesState} from '../../../../Organisms/Features'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {OrderNumber, QRCodeValue} from '../../components'
import {Order, OrderStatus, ShippingType} from '../../Order.types'
import {extractMultipleOrderNumbers, getExtras, getUnloadingMethod} from '../../Order.utils'
import {usePlants} from '../hooks'

import {DetailsField} from './DetailsField'
import {RequestedDateField} from './RequestedDateField'
import * as utils from './utils'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  }
}))

interface MainOrderDetailsProps {
  order?: Order
  deliveries?: Delivery[]
}

export const MainOrderDetails: React.FC<MainOrderDetailsProps> = ({order, deliveries}) => {
  const {classes} = useStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {getFeature} = useFeaturesState()
  const delivery = deliveries && deliveries.length ? deliveries[0] : undefined
  const {isFetching: isPlantsFetching, data: plant} = usePlants(
    order?.businessLine,
    delivery?.plantName,
    delivery?.plantId
  )
  const cementPlant = plant || delivery?.plantDetails

  if (!order) return null

  const {orderStatus, businessLine, shippingType, schedules, shippingAddress, lineItems} = order
  const {arrivalSpacing, quantityPerHour} = schedules[0] || {}
  const {customerMaterialDescription} = lineItems[0]

  const placedDate = utils.formattedDate(order.placedDate, language)

  return (
    <div>
      <div className={classes.container} data-test-id={`order-full-details-${order.orderId}`}>
        {!order.isHaveMoreThanOne && (
          <DetailsField
            label={t('order.orderDetails.orderNumber')}
            value={<OrderNumber order={order} entryPoint="Order details page" t={t} />}
          />
        )}
        <DetailsField
          dataTestId="order-status"
          label={t('order.orderDetails.statusLabel')}
          value={
            <Box display="flex" alignItems="center" gap={0.5}>
              <StatusIcon status={order.orderStatus} shippingType={shippingType} small />
              {t(`order.orderStatusLabel.${orderStatus}`)}
            </Box>
          }
        />
        <DetailsField
          label={t('order.orderDetails.placedDate')}
          dataTestId="placed-date"
          value={placedDate[0]}
          subValue={placedDate[1]}
        />
        {order.orderStatus === OrderStatus.Cancelled && (
          <DetailsField
            dataTestId="canceler-email-name"
            label={t('order.orderDetails.cancelledByLabel')}
            value={order.orderCancelerName ? order.orderCancelerName : undefined}
            subValue={order.orderCancelerEmail ? order.orderCancelerEmail : undefined}
          />
        )}
        <RequestedDateField order={order} />
        <DetailsField
          dataTestId="customer-reference"
          label={t('order.orderDetails.customerReference')}
          value={order?.customerReference || '-'}
        />
        <DetailsField
          dataTestId="contract-number"
          label={t('order.orderDetails.contractNumber')}
          value={order?.contractNumber}
          businessLine={order.businessLine}
          showForBusinessLines={[BusinessLineType.CEM, BusinessLineType.AGG]}
        />
        <DetailsField
          dataTestId="shipping-type"
          label={t('order.orderDetails.shippingTypeLabel')}
          value={order.shippingType ? t(`order.orderDetails.${order.shippingType}`) : undefined}
        />

        {(arrivalSpacing || quantityPerHour) && (
          <DetailsField
            dataTestId="unload-spacing"
            label={
              quantityPerHour ? t('order.orderDetails.flowRate') : t('order.orderDetails.spacing')
            }
            value={utils.formattedFlowRateSpacing(order, t)}
            businessLine={businessLine}
            showForBusinessLines={[BusinessLineType.RMC]}
          />
        )}

        <DetailsField
          dataTestId="unloading-method"
          label={t('order.orderDetails.unloadingMethod.label')}
          value={getUnloadingMethod(t, order.unloadingMethod)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.RMC]}
        />
        <DetailsField
          dataTestId="order-placer-name"
          label={t('order.orderDetails.orderPlacerName')}
          value={order.orderPlacerName}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM, BusinessLineType.AGG]}
        />
        <DetailsField
          dataTestId="vehicle-type"
          label={t('order.orderDetails.vehicleType')}
          value={utils.getModeOfTransport(t, deliveries)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM]}
          shippingType={shippingType}
          showForShippingType={ShippingType.Deliver}
        />
        <DetailsField
          dataTestId="vehicle-licence"
          label={t('order.orderDetails.vehicleLicencePlate')}
          value={utils.getTruckName(getFeature, deliveries)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM]}
        />
        <DetailsField
          dataTestId="trailer-licence-plate"
          label={t('order.orderDetails.trailer')}
          value={utils.getTrailer(deliveries)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM]}
          shippingType={shippingType}
          showForShippingType={ShippingType.Deliver}
        />
        <DetailsField
          dataTestId="driver-name"
          label={t('order.orderDetails.driverName')}
          value={utils.getDriverName(deliveries)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM]}
          shippingType={shippingType}
          showForShippingType={ShippingType.Deliver}
        />
        <Features name="RMCTestReports">
          <DetailsField
            dataTestId="test-booked"
            label={t('order.orderDetails.testBooked')}
            value={t(`order.isTestBooked.${order?.isTestBooked ? 'booked' : 'none'}`)}
            businessLine={businessLine}
            showForBusinessLines={[BusinessLineType.RMC]}
          />
        </Features>
        <DetailsField
          dataTestId="order-extras"
          label={t('order.orderDetails.extras')}
          value={getExtras(order)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.RMC]}
        />
        <Features name="OrderDetailsPinCode">
          <DetailsField
            dataTestId="loading-number"
            label={t('order.orderDetails.loadingNumber')}
            value={utils.getLoadingNumber(order, deliveries)}
            businessLine={businessLine}
            showForBusinessLines={[BusinessLineType.CEM, BusinessLineType.AGG]}
            shippingType={shippingType}
            showForShippingType={ShippingType.Collect}
          />
        </Features>
        <Features name="ShowRecipeNumber">
          <DetailsField
            label={t('order.orderDetails.recipeNumber')}
            dataTestId="recipe-number"
            value={order.recipeNumber}
          />
        </Features>
        <DetailsField
          dataTestId="material-and-volume"
          label={t('order.orderDetails.materialAndVolume')}
          value={utils.getMaterialAndVolume(order)}
          subValue={customerMaterialDescription}
        />
        <DetailsField
          dataTestId="plant-details"
          label={t('order.orderDetails.plantName')}
          value={isPlantsFetching ? <Skeleton width="60%" /> : cementPlant?.plantName || '-'}
          subValue={utils.getAddress(getFeature, cementPlant)}
          businessLine={businessLine}
          showForBusinessLines={[BusinessLineType.CEM]}
        ></DetailsField>
        {order.isHaveMoreThanOne && order.orderIds && (
          <DetailsField
            dataTestId="order-number"
            label={t('order.orderDetails.orderNumber')}
            value={extractMultipleOrderNumbers(order.orderIds).join(', ')}
          />
        )}
        <DetailsField
          label={t('order.orderDetails.destination')}
          dataTestId="order-destination"
          value={shippingAddress?.siteName}
          subValue={utils.getAddress(getFeature, shippingAddress)}
          fullWidth
        />
      </div>
      <div data-test-id="order-details-supplement">
        <QRCodeValue order={order} deliveries={deliveries} />
        <DeliveryProgressBar order={order} noContainer />
      </div>
    </div>
  )
}

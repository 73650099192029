import {useTranslation} from 'react-i18next'

import {SiteContact} from '../../Order.types'

import {DetailsField} from './DetailsField'

interface ContactDetailsFieldProps {
  siteContact?: SiteContact
}

export const ContactDetailsField: React.FC<ContactDetailsFieldProps> = ({siteContact}) => {
  const {t} = useTranslation()

  if (!siteContact) return null

  return (
    <DetailsField
      label={t('order.orderDetails.siteContactName')}
      value={siteContact.siteContactName}
      subValue={
        <span style={{color: '#016AD4'}} data-test-id="site-contact-number">
          {siteContact.siteContactNumber}
        </span>
      }
      dataTestId="site-contact-name"
    />
  )
}

import {Box, Grid, Paper} from '@mui/material'
import {grey} from '@mui/material/colors'
import React from 'react'

import {BigHeader, DisclaimerText, StrongNumber, StrongNumberDescription} from './Typography'

interface InfoBannerType {
  strongNumbers: string
  description: string
  smallScreen: boolean
}
export const InfoBanner: React.FC<InfoBannerType> = ({strongNumbers, description, smallScreen}) => (
  <Box data-test-id="info-banner-card">
    <Box
      data-test-id="info-banner-card-title"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box mb={smallScreen ? 1 : 2}>
        <StrongNumber smallScreen={smallScreen} variant="h2" align="center">
          {strongNumbers}
        </StrongNumber>
      </Box>
      <Box>
        <StrongNumberDescription variant="body1" align="center" smallScreen={smallScreen}>
          {description}
        </StrongNumberDescription>
      </Box>
    </Box>
  </Box>
)

interface Type {
  title: string
  disclaimer: string
  cards: {strongNumbers: string; description: string}[]
  smallScreen?: boolean
}
export const InfoBannerList: React.FC<Type> = ({title, disclaimer, cards, smallScreen = false}) => {
  return (
    <Paper
      elevation={1}
      data-test-id="info-banner"
      sx={{
        borderRadius: 1,
        px: smallScreen ? 2.5 : 6.25,
        py: 5.5
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box mb={3}>
          <BigHeader smallScreen={smallScreen} variant="h3">
            {title}
          </BigHeader>
        </Box>

        <Grid container rowGap={2} columnGap={{xs: 0, md: 3}} justifyContent="center">
          {cards.map((card, index) => (
            <Grid
              key={card.strongNumbers}
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              sx={{
                px: 2.5,
                borderRight: index + 1 !== cards.length ? '1px solid #000' : 'none',
                borderColor: grey[300]
              }}
            >
              <InfoBanner
                strongNumbers={card.strongNumbers}
                description={card.description}
                smallScreen={smallScreen}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <DisclaimerText variant="caption" smallScreen={smallScreen}>
            {disclaimer}
          </DisclaimerText>
        </Box>
      </Box>
    </Paper>
  )
}

/* eslint-disable complexity */
import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Content, useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import React from 'react'
import {useSelector} from 'react-redux'
import {useScroll} from 'react-use'
import {v4 as uuidV4} from 'uuid'

import {api} from '../../../App.global'
import C60PageLogo from '../../../Assets/c60_page_assets/c60_page_logo.svg'
import {useQueryParam} from '../../../Hooks/useQueryParam'
import {useLeadSubscriptions} from '../../../Molecules/LeadBanners/hooks'
import {LeadSubscriptionsBody} from '../../../Molecules/LeadBanners/types'
import {VerticalTabsShowcase} from '../../../Molecules/VerticalTabsShowcase/VerticalTabsShowcase'
import {selectSelectedCustomerFilter} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {AppState} from '../../../Root.store'
import {BottomMotoText, InfoBannerList} from '../components'
import {PartnerCard} from '../components/Card'
import {midTabs, endTabs, topBanner, endBanner, midBanner} from '../models'

export const C60PartnerPage: React.FC = () => {
  const queryParams = useQueryParam()
  const page = queryParams?.has('page') ? queryParams.get('params') : 'partners'
  const screenSize = useBreakPoints()
  const pageRef = React.useRef(null)
  const {y: scrollY} = useScroll(pageRef)
  const [analyticsId, setAnalyticsId] = React.useState<string>(uuidV4())
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const customer = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )
  const {country, user_id: userId} = user || {}
  const countryCode = country || ''
  const customerId = customer?.customerId || ''
  const {data, isFetching, isError} = useLeadSubscriptions(customerId || '')
  const queryClient = useQueryClient()

  interface PartnersAnalyticsType {
    analyticsId: string
    customerId: string
    userId: string | undefined
    source: string | null
  }
  const analytics: PartnersAnalyticsType = {
    analyticsId,
    customerId,
    userId,
    source: page
  }

  const createLead = useMutation(
    ({
      customerId,
      analyticsId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      analytics
    }: {
      customerId: string
      analyticsId: string
      analytics: PartnersAnalyticsType
    }) => {
      const body: LeadSubscriptionsBody = {
        customerId,
        analyticsId,
        countryCode
      }
      return api.post('/announcements/subscriptions', body)
    },
    {
      onSuccess: async (_, {analytics}) => {
        trackEvent('hubC60WidgetConfirm', {...analytics})
        void (await queryClient.invalidateQueries({queryKey: ['subscriptions']}))
      },
      onError: (_, {analytics}) => {
        trackEvent('hubC60PageError', {
          ...analytics,
          error: 'Subscription failed'
        })
      }
    }
  )

  const onScroll = () => trackEvent('hubC60PageScroll', {...analytics})
  const debouncedOnScroll = React.useCallback(
    debounce(() => onScroll(), 400),
    []
  )
  const onPageTabClick = (tab: string) => trackEvent('hubC60PageTabClick', {...analytics, tab})

  const onButtonClick = () => {
    createLead.mutate({customerId, analyticsId, analytics})
    trackEvent('hubC60PageWidgetClick', {...analytics})
  }

  const onHover = (widget: 'topBanner' | 'endBanner') => {
    trackEvent('hubC60PageWidgetHover', {
      ...analytics,
      source: widget
    })
  }

  React.useEffect(() => {
    trackEvent('hubC60Page', {...analytics})
  }, [])

  React.useEffect(() => {
    if (queryParams?.has('analyticsId')) {
      setAnalyticsId(queryParams.get('analyticsId') || '')
    }
  }, [queryParams])

  React.useEffect(() => {
    debouncedOnScroll()
  }, [scrollY])

  const {status} = data || {}

  const bannerType: 'info' | 'success' | 'error' | undefined =
    isError || createLead.isError
      ? 'error'
      : status === 'canSubscribe'
      ? 'info'
      : status === 'subscribed'
      ? 'success'
      : undefined

  const bannerText =
    status === 'canSubscribe'
      ? topBanner.alertNeutral
      : status === 'subscribed'
      ? topBanner.alertSuccess
      : topBanner.alertFailed

  const smallScreen = ['sm', 'xs'].includes(screenSize)

  return (
    <Content>
      <Box px={smallScreen ? 2 : 6.5} py={11} ref={pageRef}>
        <Box data-test-id="c60-partner-page" display="flex" flexDirection="column">
          <Box data-test-id="c60-card-1" mb={2}>
            <PartnerCard
              cardId="topBanner"
              title={topBanner.title}
              subtitle={topBanner.subtitle}
              moto={topBanner.moto}
              leadStatus={status}
              imageUrls={topBanner.imageUrls}
              action={() => onButtonClick()}
              handleHover={() => onHover('topBanner')}
              alert={{type: bannerType || 'error', text: bannerText}}
              isFetching={isFetching || createLead.isLoading}
              smallScreen={smallScreen}
              logo={C60PageLogo}
            />
          </Box>
          <Box data-test-id="c60-card-2" mb={2}>
            <VerticalTabsShowcase
              showcaseId="mid"
              tabs={midTabs}
              smallScreen={smallScreen}
              onTabClick={onPageTabClick}
            />
          </Box>
          <Box data-test-id="c60-card-3" mb={2}>
            <InfoBannerList
              title={midBanner.title}
              disclaimer={midBanner.disclaimer}
              cards={midBanner.cards}
              smallScreen={smallScreen}
            />
          </Box>
          <Box data-test-id="c60-card-4" mb={2}>
            <VerticalTabsShowcase
              showcaseId="end"
              tabs={endTabs}
              smallScreen={smallScreen}
              showcaseTitle="Make your sales, production, materials, and delivery better than ever"
              showcaseSubtitle="Thanks to the expertise of our co-inventors, these ready mix concrete producers have identified important opportunities:"
              onTabClick={onPageTabClick}
            />
          </Box>
          <Box data-test-id="c60-card-5" mb={2}>
            <PartnerCard
              cardId="endBanner"
              title={endBanner.title}
              subtitle={endBanner.subtitle}
              moto={endBanner.moto}
              leadStatus={status}
              imageUrls={endBanner.imageUrls}
              action={() => onButtonClick()}
              handleHover={() => onHover('endBanner')}
              alert={{type: bannerType || 'error', text: bannerText}}
              isFetching={isFetching}
              smallScreen={smallScreen}
            />
          </Box>
          <Box data-test-id="c60-text-bottom" mt={4}>
            <BottomMotoText variant="body1" align="center">
              Turn your data into dollars.
            </BottomMotoText>
          </Box>
        </Box>
      </Box>
    </Content>
  )
}

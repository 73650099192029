import {dateFormatter, getUtcOffset, GridRowsProp, timeFormatter, Typography} from '@hconnect/uikit'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import {Box, Card, CardContent, Divider, SvgIconProps, SvgIcon, Grid, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as DumpTruckSVG} from '../../../Assets/dump_truck.svg'
import {ReactComponent as DumpTruckReversedSVG} from '../../../Assets/dump_truck_reversed.svg'
import {ReactComponent as LocationCitySVG} from '../../../Assets/location_city.svg'
import {ReactComponent as ShipSVG} from '../../../Assets/ship.svg'
import {ReactComponent as TrainSVG} from '../../../Assets/train.svg'
import {PageNames} from '../../../common/constants'
import {Address} from '../../../Molecules/Address'
import {CustomerFeedbackBanner} from '../../../Molecules/CustomerFeedbackProgramme/CustomerFeedbackBanner'
import {CustomerFeedbackPanel} from '../../../Molecules/CustomerFeedbackProgramme/CustomerFeedbackPanel'
import {CustomSkeleton} from '../../../Molecules/CustomSkeleton'
import {FieldValue} from '../../../Molecules/FieldDetails'
import {C60Lead} from '../../../Molecules/LeadBanners'
import {useResponsiveGridStyles} from '../../../Molecules/Responsive.utils'
import StatusIcon from '../../../Molecules/StatusIcons'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {statusFromOrderStatus} from '../../../Organisms/Orders'
import {QuantityUomToSymbolComponent} from '../Order.components'
import {ModeOfTransport, Order, ShippingType} from '../Order.types'
import {getEventLocationOffset} from '../Order.utils'

import {PlacedDate} from './PlacedDate'
import {RequestedDate} from './RequestedDate'
import {TimezoneWarning} from './TimezoneWarning'
import {ViewDetailsButton} from '../../../Molecules/Buttons/ViewDetailsButton'
import MaterialDescription from '../../../Molecules/MaterialDescription'
import {BusinessLineType} from '../../../common/types'

interface ShippingTypeIconType extends SvgIconProps {
  vehicle?: ModeOfTransport
  shippingType?: ShippingType
  colorHtml?: string
}
const ShippingTypeIcon: React.FC<ShippingTypeIconType> = ({
  vehicle = ModeOfTransport.Truck,
  shippingType = ShippingType.Deliver,
  colorHtml,
  ...otherProps
}) => {
  let icon
  switch (vehicle) {
    case ModeOfTransport.Train:
      icon = <TrainSVG />
      break

    case ModeOfTransport.Ship:
      icon = <ShipSVG />
      break

    case ModeOfTransport.Truck:
      icon = shippingType === ShippingType.Deliver ? <DumpTruckSVG /> : <DumpTruckReversedSVG />
      break

    default:
      icon = <DumpTruckSVG />
      break
  }
  return (
    <SvgIcon {...otherProps} sx={{path: {fill: colorHtml}}}>
      {icon}
    </SvgIcon>
  )
}

interface OrderCardType {
  order: Order
  viewOrderDetailsPage?: (row: Order) => void
}

export const OrderCard: React.FC<OrderCardType> = ({order, viewOrderDetailsPage}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {palette} = useTheme()
  const {getFeature} = useFeaturesState()

  const userOffset = getUtcOffset(order.shippingDate)
  const eventLocationOffset = getEventLocationOffset(order)
  const orderStatus = order ? statusFromOrderStatus(order.orderStatus, order.shippingType) : null
  const {materialDescription, customerMaterialDescription, quantity, quantityUom} =
    order?.lineItems[0] || {}

  return (
    <Card
      sx={{
        marginBottom: 2
      }}
      variant="outlined"
      data-test-id={`order-card-${order.orderId}`}
    >
      <CardContent sx={{paddingTop: 3}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" data-test-id="order-card-status">
              <StatusIcon status={order.orderStatus} shippingType={order.shippingType} small />
              <Box mx={0.5} />
              <Typography
                variant="h4"
                style={{
                  textTransform: 'capitalize',
                  lineHeight: '100%'
                }}
              >
                {t(`order.orderStatusLabel.${orderStatus}`)}
              </Typography>
            </Box>
            <OpenInFullIcon
              onClick={() => viewOrderDetailsPage && viewOrderDetailsPage(order)}
              data-test-id={`order-card-expand-icon-${order.orderId}`}
            />
          </Box>
          <Box my={1} />
          <Box data-test-id="order-card-requested-date">
            <FieldValue
              label={t('order.orderDetails.requestedDate')}
              dataTestId="requested-date"
              gutter
            >
              {order.businessLine === BusinessLineType.CEM ? (
                <Box py={0.5}>
                  <RequestedDate
                    t={t}
                    order={order}
                    language={language}
                    userOffset={userOffset}
                    eventLocationOffset={eventLocationOffset}
                  />
                </Box>
              ) : order?.schedules && order.schedules.length ? (
                <Box py={0.5}>
                  {`${dateFormatter(
                    order?.schedules[0].scheduleStartDate,
                    language,
                    eventLocationOffset
                  )} ${timeFormatter(
                    order?.schedules[0].scheduleStartDate,
                    language,
                    eventLocationOffset
                  )}`}
                  <TimezoneWarning
                    t={t}
                    order={order}
                    userOffset={userOffset}
                    eventLocationOffset={eventLocationOffset}
                  />
                </Box>
              ) : null}
            </FieldValue>
          </Box>
          <Box data-test-id="order-card-placed-date">
            <FieldValue
              label={t('order.orderDetails.placedDate')}
              dataTestId="placed-date"
              gutter
              breakable
            >
              <Box py={0.5}>
                <PlacedDate order={order} />
              </Box>
            </FieldValue>
          </Box>
          <Box data-test-id="order-card-po-number" width="100%">
            <FieldValue
              dataTestId="customer-reference"
              label={t('order.orderDetails.customerReference')}
              gutter
            >
              <Box display="flex" flexDirection="column" py={0.5}>
                <Typography variant="body1">{order?.customerReference}</Typography>
                <Typography variant="body2">{order?.orderNumber}</Typography>
              </Box>
            </FieldValue>
          </Box>
          <Box data-test-id="order-card-material-volume" width="100%" pr={8}>
            <FieldValue
              label={t('order.orderDetails.materialAndVolume')}
              dataTestId="order-card-material"
              gutter
            >
              <Grid container py={0.5}>
                <Grid item xs={9}>
                  <MaterialDescription
                    materialDescription={materialDescription}
                    customerMaterialDescription={customerMaterialDescription}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box mr={2}>
                      {quantity}
                      <QuantityUomToSymbolComponent uom={quantityUom} />
                    </Box>
                    <ShippingTypeIcon
                      vehicle={order.modeOfTransport}
                      shippingType={order.shippingType}
                      fontSize="medium"
                      colorHtml={palette.text.primarySoft}
                    />
                  </Box>
                </Grid>
              </Grid>
            </FieldValue>
          </Box>
          <Box width="100%">
            <Divider variant="middle" />
          </Box>
          <Box my={1} />
          <Box
            data-test-id="order-card-destination-address"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <Box mr={1}>
              <SvgIcon fontSize="large">
                <LocationCitySVG fill={palette.text.primarySoft} />
              </SvgIcon>
            </Box>
            <FieldValue
              label={t('order.orderDetails.destination')}
              dataTestId="order-destination"
              noLabel
            >
              {order.shippingAddress.siteName}
              {order.shippingAddress.street && (
                <Address
                  street={order.shippingAddress.street}
                  city={order.shippingAddress.city || ''}
                  state={order.shippingAddress.state || ''}
                  postalCode={order.shippingAddress.postalCode || ''}
                  showState={getFeature('ShowState')}
                />
              )}
            </FieldValue>
          </Box>
          <Box my={1} />
          <Box width="100%">
            <ViewDetailsButton
              onClick={() => viewOrderDetailsPage && viewOrderDetailsPage(order)}
              dataTestId={`order-card-button-${order.orderId}`}
              value={t('viewDetails')}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

type KeyFieldType = string

interface Type {
  loading?: boolean
  rows: GridRowsProp<Order>
  keyField: KeyFieldType
  viewOrderDetailsPage?: (row: Order) => void
  countryCode: string
  fullScreen: boolean
  customerId: string
  screenSize: BreakPoint
  analytics: {
    userId: string | undefined
    userMail: string | null | undefined
    userCountry: string | null | undefined
    userRoles: any[] | undefined
    userIsInternal: boolean | undefined
    customerId: string | undefined
    customerName: string | undefined
    filteredDateRange: {
      startDate: any
      endDate: any
    }
  }
}

export const OrdersCardList: React.FC<Type> = ({
  rows,
  loading,
  keyField,
  viewOrderDetailsPage,
  countryCode,
  screenSize,
  fullScreen,
  customerId,
  analytics
}) => {
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const {classes: responsiveClasses} = useResponsiveGridStyles()

  if (loading) {
    return <CustomSkeleton />
  }

  const banners = (
    <>
      <Features name="C60LeadWidget">
        <C60Lead
          countryCode={countryCode}
          fullScreen={fullScreen}
          customerId={customerId}
          analytics={analytics}
          isMediumScreen={screenSize === 'md'}
          screenSize={screenSize}
          entryPoint={PageNames.ORDERS}
        />
        <Box mt={2} />
      </Features>
      <CustomerFeedbackBanner
        setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
        entryPoint={PageNames.ORDERS}
      />
      <Box mt={2} />
    </>
  )
  return (
    <>
      <Box height="100%" width="100%">
        {rows.length === 0
          ? banners
          : rows.map((row, index) => (
              <React.Fragment key={row[keyField]}>
                <OrderCard order={row} viewOrderDetailsPage={viewOrderDetailsPage} />
                {index === 0 && banners}
              </React.Fragment>
            ))}
      </Box>
      <Overlay
        data-test-id="customerFeedbackBannerOverlay"
        isVisible={showCustomerPanel}
        overlayClassName={responsiveClasses.overlay}
        contentClassName={responsiveClasses.overlayContent}
      >
        <CustomerFeedbackPanel
          close={() => setShowCustomerPanel(false)}
          entryPoint={PageNames.ORDERS}
        />
      </Overlay>
    </>
  )
}

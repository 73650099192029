import {combineReducers} from 'redux'

import {
  FETCH_LATEST_INVOICES_PENDING,
  FETCH_LATEST_INVOICES_SUCCESS,
  OverviewActionTypes,
  OverviewPageState
} from './Action.types'

const initialState: OverviewPageState = {
  isFetching: false,
  items: [],
  dateFilter: {
    startDate: null,
    endDate: null
  },
  sortOrder: {
    key: 'invoiceDate',
    asc: false
  }
}

const invoice = (state = initialState, action: OverviewActionTypes) => {
  switch (action.type) {
    // case INVOICES_RESET_DATE_FILTER:
    //   return {
    //     ...state,
    //     dateFilter: { startDate: null, endDate: null },
    //   }
    case FETCH_LATEST_INVOICES_PENDING:
      return {
        ...state,
        isFetching: true
      }

    case FETCH_LATEST_INVOICES_SUCCESS:
      if (!action.payload) {
        return state
      }
      // eslint-disable-next-line no-case-declarations
      const items = action.payload
      return {
        ...state,
        isFetching: false,
        items,
        lastUpdated: action.receivedAt
      }

    default:
      return state
  }
}
export interface OverviewState {
  invoice: OverviewPageState
}
export default combineReducers({
  invoice
})

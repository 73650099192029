import {Divider, Skeleton} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {Features} from '../../../../Organisms/Features'
import {PermissionTypes, usePermissions} from '../../../../Permissions'
import {OrderCancellationButton} from '../../components'
import {Order} from '../../Order.types'
import {OrderHistoryDetails} from '../OrderHistoryDetails'

import {DetailsField} from './DetailsField'
import {ExportDeliveriesButton} from './ExportDeliveriesButton'
import {getDeliveryInstructions} from './utils'

interface SideOrderDetailsProps {
  order?: Order
  customerId: string
  isFetching: boolean
}

export const SideOrderDetails: React.FC<SideOrderDetailsProps> = ({
  order,
  customerId,
  isFetching
}) => {
  const {hasPermission} = usePermissions()
  const {t} = useTranslation()

  if (isFetching) return <Skeleton variant="rectangular" height={200} />

  if (!order) return null

  const hideCancelButtonStatuses = ['delivered', 'closed', 'paymentPending', 'rejected']
  const hideCancelButton = hideCancelButtonStatuses.includes(order.orderStatus)
  const hasChangeOrderPermission = hasPermission(PermissionTypes.CHANGE_ORDERS)

  return (
    <>
      <DetailsField
        label={t('order.orderDetails.deliveryInstructions')}
        dataTestId="delivery-instructions"
        value={getDeliveryInstructions(order)}
      />
      <ExportDeliveriesButton order={order} customerId={customerId} />
      <OrderHistoryDetails order={order} />
      <Features name="OrderIntakeOrderCancellation">
        {!hideCancelButton && hasChangeOrderPermission && (
          <>
            <Divider variant="fullWidth" />
            <OrderCancellationButton order={order} />
          </>
        )}
      </Features>
    </>
  )
}

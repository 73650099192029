import {PrimaryCell, Typography, dateFormatter, timeFormatter} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import {TFunction} from 'i18next'
import moment from 'moment'
import React from 'react'

import StatusTooltip from '../../../Molecules/StatusTooltip'
import {useFeaturesState} from '../../../Organisms/Features'
import {Order} from '../Order.types'

import {TimezoneWarning} from './TimezoneWarning'

const ONE_DAY_IN_MS = 86399

const OvernightWarning: React.FC<{t: TFunction; orderId: string}> = ({t, orderId}) => {
  return (
    <StatusTooltip
      title={t('order.overnightTooltip') || ''}
      placement="right-start"
      data-test-id={`order-overnight-tooltip-${orderId}`}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mb={0.4}
        style={{gap: '5px'}}
        fontSize={12}
      >
        <WarningIcon fontSize="inherit" color="error" />
        <Typography customColor="textError" variant="caption">
          {t('order.overnight')}
        </Typography>
      </Box>
    </StatusTooltip>
  )
}

const useStyles = makeStyles(() => ({
  body2: {
    lineHeight: '1.4em'
  }
}))

interface Props {
  order: Order
  language: string
  t: TFunction
  userOffset: number
  eventLocationOffset?: number
  hideOvernightWarning?: boolean
}

// eslint-disable-next-line complexity
export const RequestedDate: React.FC<Props> = ({
  order,
  language,
  t,
  userOffset,
  eventLocationOffset,
  hideOvernightWarning
}) => {
  const {getFeature} = useFeaturesState()
  const {body2} = useStyles()
  const isTimeWindowEnabled = getFeature('OrderTimeWindow')
  const isEndTimeRangeActivated = getFeature('EndDateTimeRange')

  const schedules = order.schedules || []
  const {scheduleStartDate, scheduleEarliestStartDate, scheduleEndDate} = schedules[0]
  const beginTimeRange = isEndTimeRangeActivated
    ? scheduleStartDate
    : scheduleEarliestStartDate || scheduleStartDate
  const endTimeRange = isEndTimeRangeActivated ? scheduleEndDate : scheduleStartDate
  const isIt235959 =
    moment(scheduleStartDate).diff(moment(scheduleEarliestStartDate), 'seconds') === ONE_DAY_IN_MS
  const requestedDate = dateFormatter(beginTimeRange, language, eventLocationOffset)
  // if feature toggle is disabled
  if (!isEndTimeRangeActivated && (!isTimeWindowEnabled || isIt235959)) {
    return <>{requestedDate}</>
  }
  // if some of the properties are missing return the date and '-' as identification for missing props
  if (!beginTimeRange || !endTimeRange) {
    return (
      <>
        <Typography color="textPrimary" variant="subtitle1">
          {requestedDate}
        </Typography>
        <PrimaryCell>-</PrimaryCell>
      </>
    )
  }
  // if the props are the same return time and date
  const dateAndTimesAreSame = moment(beginTimeRange).isSame(moment(endTimeRange))
  if (dateAndTimesAreSame) {
    return (
      <>
        <Typography color="textPrimary" variant="subtitle1">
          {requestedDate}
        </Typography>
        <PrimaryCell>
          {t('order.requestedTime', {
            time: timeFormatter(beginTimeRange, language)
          })}
        </PrimaryCell>
        <TimezoneWarning
          t={t}
          order={order}
          userOffset={userOffset}
          eventLocationOffset={eventLocationOffset}
        />
      </>
    )
  }
  // if the difference is more or equal then one day return the suffix '+{number of days}d'
  // otherwise return the time range and date bellow
  const beginTimeAtLocation = moment(beginTimeRange).utcOffset(eventLocationOffset || userOffset)
  const endTimeAtLocation = moment(endTimeRange).utcOffset(eventLocationOffset || userOffset)
  const plusDays = endTimeAtLocation.diff(beginTimeAtLocation, 'days')
  const dayAfter = endTimeAtLocation.isAfter(beginTimeAtLocation, 'days')
  return (
    <>
      {dayAfter && !hideOvernightWarning ? (
        <OvernightWarning t={t} orderId={order.orderId} />
      ) : null}

      <Typography color="textPrimary" variant="subtitle1">
        {requestedDate}
      </Typography>

      <PrimaryCell>
        <Typography
          variant="body2"
          classes={{
            body2: dayAfter ? body2 : undefined
          }}
        >
          {timeFormatter(beginTimeRange, language, eventLocationOffset)}
          {' - '}
          {timeFormatter(endTimeRange, language, eventLocationOffset)}
          <sup>{plusDays !== 0 ? t('order.requestedDatePlusOne', {count: plusDays}) : null}</sup>
        </Typography>
      </PrimaryCell>
      <TimezoneWarning
        t={t}
        order={order}
        userOffset={userOffset}
        eventLocationOffset={eventLocationOffset}
      />
    </>
  )
}

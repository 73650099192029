import React, {createContext, useContext, useState} from 'react'

export interface MaintenanceState {
  showMaintenanceScreen: boolean
  startDate?: Date
  durationInHours?: number
}

interface MaintenanceScreenContext {
  maintenanceState?: MaintenanceState
  setMaintenanceState?: (maintenanceState: MaintenanceState) => void
}
const Context = createContext<MaintenanceScreenContext>({})

export const MaintenanceScreenProvider = ({children}) => {
  const [maintenanceState, setMaintenanceState] = useState({showMaintenanceScreen: false})
  const value = {maintenanceState, setMaintenanceState}

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useMaintenanceScreen = () => useContext(Context)

import CloseIcon from '@mui/icons-material/Close'
import {Button, Theme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {BusinessLineType} from '../../../../common/types'
import {Features, useFeaturesState} from '../../../../Organisms/Features'
import {usePermissions} from '../../../../Permissions'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {
  DeliveryArrivalTime,
  DeliveryLeftAt,
  DeliveryStatus,
  DeliveryTicketNumber,
  DeliveryUnloadFinished,
  DeliveryVehicle,
  PricingInformation,
  TimeAndDateDisplayedForDoneColumn
} from '../../components/DeliveryFields'
import {QuantityUomToSymbolComponent} from '../../Order.components'
import {Order, ShippingType} from '../../Order.types'

import {DetailsField} from './DetailsField'
import {getDeliveryToggles} from './utils'
import {ETADeliveryTrucks} from '../../../../Molecules/ETADeliveryTrucks/ETADeliveryTrucks'
import {PageNames} from '../../../../common/constants'

interface DeliveryDetailsProps {
  delivery: Delivery
  order: Order
  close: () => void
  analytics?: any
  customerId: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  cardTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#54708C'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2)
  }
}))

export const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({
  delivery,
  order,
  close,
  analytics,
  customerId
}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const features = useFeaturesState()
  const permissions = usePermissions()

  const toggles = getDeliveryToggles(features, permissions, order)
  const {eticket: {ageOfConcrete = 0} = {}} = delivery || {}

  return (
    <>
      <div className={classes.header}>
        <span className={classes.cardTitle}>{t('order.expand.deliveryDetails')}</span>
        <Button
          variant="text"
          size="large"
          onClick={() => close()}
          data-test-id="selected-orders-close"
        >
          <CloseIcon scale={2} sx={{color: '#00274D'}} />
        </Button>
      </div>
      <div className={classes.content}>
        <DeliveryStatus delivery={delivery} toggles={toggles} customerId={customerId} />
        <DetailsField
          label={t('order.expand.deliveryNumber')}
          dataTestId="ticket-number-field"
          value={
            <DeliveryTicketNumber
              delivery={delivery}
              toggles={toggles}
              customerId={customerId}
              analytics={analytics}
            />
          }
        />
        <DetailsField
          dataTestId={`leftAt-${delivery.deliveryId}`}
          label={
            toggles.isDeliveryLoadFinished
              ? t('order.expand.leftAt')
              : t('order.expand.shippingDate')
          }
          value={
            <DeliveryLeftAt
              delivery={delivery}
              isDeliveryLoadFinished={toggles.isDeliveryLoadFinished}
              isSloCountry={toggles.isSloCountry}
              businessLine={order.businessLine}
            />
          }
          businessLine={order.businessLine}
          showForBusinessLines={[BusinessLineType.CEM, BusinessLineType.AGG]}
        />
        <DetailsField
          dataTestId={`arrival-${delivery.deliveryId}`}
          label={t('order.expand.arrival')}
          businessLine={order.businessLine}
          showForBusinessLines={[BusinessLineType.RMC]}
          value={<DeliveryArrivalTime delivery={delivery} />}
        />
        <DetailsField
          dataTestId={`unloadFinished-${delivery.deliveryId}`}
          businessLine={order.businessLine}
          label={t('order.expand.deliveredOn')}
          showForBusinessLines={[BusinessLineType.AGG]}
          shippingType={order.shippingType}
          showForShippingType={ShippingType.Deliver}
          value={<DeliveryUnloadFinished delivery={delivery} />}
        />
        <Features name="AggColumnsForRmc" resolution="disabled">
          <DetailsField
            dataTestId={`done-${delivery.deliveryId}`}
            label={t('order.expand.deliveredOn')}
            value={<TimeAndDateDisplayedForDoneColumn delivery={delivery} />}
            businessLine={order.businessLine}
            showForBusinessLines={[BusinessLineType.RMC]}
          />
        </Features>
        <DetailsField
          label={toggles.isRMC ? t('order.expand.quantity') : t('order.expand.qtyLoaded')}
          value={
            <>
              {delivery.loadQuantity}{' '}
              <QuantityUomToSymbolComponent uom={delivery.loadQuantityUom || ''} />
            </>
          }
          dataTestId="loadQuantity-value"
        />
        <DetailsField
          label={t(`order.expand.modeoftransport.${delivery.modeOfTransport || 'others'}`)}
          dataTestId="vehicle"
          value={
            <DeliveryVehicle
              delivery={delivery}
              customerId={customerId}
              toggles={toggles}
              analytics={analytics}
            />
          }
        />
        {delivery.returnedDelivery && (
          <>
            <DetailsField
              label={t('order.expand.returnedDeliveryQuantity')}
              value={
                <>
                  {delivery.returnedDelivery.quantity}{' '}
                  <QuantityUomToSymbolComponent uom={delivery.returnedDelivery.unit} />
                </>
              }
              dataTestId="returned-quantity-value"
            />
            <DetailsField
              label={t('order.expand.returnedDeliveryNumber')}
              dataTestId="returned-id"
              value={delivery.returnedDelivery.deliveryId?.split('.')[1]}
            />
          </>
        )}

        {delivery.pricing && (
          <DetailsField
            label={t('invoiceDetail.lineItems.expand.unitPrice')}
            value={<PricingInformation delivery={delivery} />}
            dataTestId="returned-quantity-value"
          />
        )}

        <DetailsField
          label={t('order.orderDetails.plantName')}
          dataTestId="delivery-plant-details"
          value={delivery.plantName || '-'}
        />

        {ageOfConcrete > 0 && (
          <DetailsField
            label={t('order.orderDetails.ageOfConcrete')}
            dataTestId="age-of-concrete"
            value={`${ageOfConcrete} ${t('order.orderDetails.minutes')}`}
            businessLine={order.businessLine}
            showForBusinessLines={[BusinessLineType.RMC]}
          />
        )}

        {order.isHaveMoreThanOne && (
          <DetailsField
            label={t('order.orderDetails.orderNumber')}
            dataTestId="delivery-sales-order-number"
            value={delivery.orderNumber}
          />
        )}
        <ETADeliveryTrucks
          t={t}
          delivery={delivery}
          order={order}
          pageName={PageNames.ORDER_DETAILS}
        />
      </div>
    </>
  )
}

import {Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link as RouterLink} from 'react-router-dom'

import {showLatestInvoices} from '../Overview.action'

import InvoiceOverviewTable from './InvoiceOverviewTable'

const useStyles = makeStyles(() => ({
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  alignLeft: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}))

interface LastInvoicesType {
  showLatestInvoices: (dateRange: number) => void
  dateRange: number
  displayDateRange: number
}
const LastInvoices: React.FC<LastInvoicesType> = ({
  showLatestInvoices,
  dateRange,
  displayDateRange
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <>
      <InvoiceOverviewTable dateRange={dateRange} displayDateRange={displayDateRange} />
      <div className={classes.alignLeft}>
        <Link
          component={RouterLink}
          to="/finance/invoices"
          aria-label="Link to finance page"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            showLatestInvoices(dateRange)
          }}
        >
          {t('overview.showAll')}
        </Link>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showLatestInvoices: (dateRange: number) => dispatch(showLatestInvoices(dateRange))
})

export default connect(null, mapDispatchToProps)(LastInvoices)

import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {CustomHeader} from '../../../Molecules/CustomHeader'
import {useIconStyles} from '../../Finance/Invoices/InvoiceDetail/components/styles'
import {Order} from '../Order.types'

import {DownloadOrdersCard} from './DownloadOrdersCard'

interface SelectedOrdersAsideProps {
  orders: Order[]
  t: TFunction
  close: () => void
  bulkDownload: (
    deliveryIds: string[],
    orderIds: string[],
    ticketsCount: number,
    combined: boolean
  ) => void
}

export const SelectedOrdersAside: React.FC<SelectedOrdersAsideProps> = ({
  orders,
  t,
  close,
  bulkDownload
}) => {
  const {classes} = useIconStyles()

  return (
    <Box p={2}>
      <CustomHeader title={t('order.selectedOrders')}>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="selected-orders-close"
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>

      <DownloadOrdersCard orders={orders} bulkDownload={bulkDownload} t={t} />
    </Box>
  )
}

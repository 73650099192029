export enum LookupSelectItems {
  ORDER = 'ORDER',
  TICKET = 'TICKET',
  BOL = 'BOL',
  PO = 'PO'
}

export enum LookupEntityParam {
  ORDER = 'orderNumber',
  TICKET = 'orderId',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  BOL = 'orderId',
  PO = 'poNumber'
}

export enum OrderViews {
  DEFAULT = 'DEFAULT',
  LOOKUP = 'LOOKUP'
}

export enum EpodStatus {
  PENDING = 'pending',
  APPROVED = 'approved'
}

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Button} from '@mui/material'
import jsPDF from 'jspdf'
import QRCode from 'qrcode.react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FieldDetails} from '../../../Molecules/FieldDetails'
import {Features} from '../../../Organisms/Features'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {Order, ShippingType} from '../Order.types'
import {BusinessLineType} from '../../../common/types'

const generatePDF = (orderNumber: string) => {
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [40, 40]
  })
  const base64Image = (document.getElementById('qrcode') as HTMLCanvasElement)?.toDataURL() || ''
  pdf.addImage(base64Image, 'png', 0, 0, 40, 40)
  pdf.save(`qrcode-${orderNumber}.pdf`)
}

interface QRCodeType {
  order: Order
  deliveries?: Delivery[]
}

export const QRCodeValue: React.FC<QRCodeType> = ({order, deliveries}) => {
  const {t} = useTranslation()

  const {businessLine, shippingType} = order

  let qrCodeValue: string | undefined
  if (businessLine === BusinessLineType.AGG) {
    qrCodeValue = order?.qrCode?.replace(/\\"/g, '"')
  } else if (businessLine === BusinessLineType.CEM && deliveries?.length) {
    qrCodeValue = deliveries[0].qrCode?.replace(/\\"/g, '"')
  }

  if (!qrCodeValue) return null

  return (
    <Features name="OrderDetailsQRCode">
      <FieldDetails
        dataTestId="qr-code-field"
        label={t('order.orderDetails.qrCode')}
        businessLine={businessLine}
        shippingType={shippingType}
        showForShippingType={ShippingType.Collect}
        showForBusinesslines={[BusinessLineType.CEM, BusinessLineType.AGG]}
        variant="horizontal"
        helpText={t('order.orderDetails.qrCodeCollect')}
        actionButton={
          <Button
            data-test-id="qr-code-download"
            variant="text"
            size="small"
            color="primary"
            style={{justifyContent: 'flex-start'}}
            onClick={() => generatePDF(order.orderNumber)}
            startIcon={<PictureAsPdfIcon />}
          >
            {t('order.orderDetails.downloadPdf')}
          </Button>
        }
        value={<QRCode id="qrcode" data-test-id="qrcode" size={100} value={qrCodeValue} />}
        gutter
      />
    </Features>
  )
}

import {Box, Button, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {selectCustomers} from '../../../Organisms/Customers'
import {CustomerStateType} from '../../../Organisms/Customers/Action.types'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {AppState} from '../../../Root.store'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

export const RequestForQuote: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const customerState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const selectedCustomer = customerState.selectedCustomer
  const {isError, isFetching} = useBulkCementOrderIntake()

  const handleRequestForQuote = () => {
    history.push('/requestForQuote')
  }

  if (!selectedCustomer) {
    return null
  }

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <div className={classes.wrapper}>
        <Button
          color="primary"
          onClick={handleRequestForQuote}
          disabled={isError || isFetching}
          data-test-id="order-intake-request-for-quoute-button"
        >
          {t('overview.requestForQuote')}
        </Button>
      </div>
    </Box>
  )
}

import {datetimeFormatter, HCThemeType} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Divider, Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomHeader} from '../../../Molecules/CustomHeader'
// eslint-disable-next-line import/no-cycle
import {FieldValue} from '../../../Molecules/FieldDetails'
import {Features} from '../../../Organisms/Features'
import {Order} from '../Order.types'

import {getOrderChangeText} from './OrderDetails.utils'

interface OrderHistoryDetailsProps {
  order: Order
}

const useStyles = makeStyles((theme: HCThemeType) => ({
  orderChangesList: {
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.text.primarySoft,
    borderLeftStyle: 'solid'
  },
  orderChange: {
    display: 'list-item',
    marginLeft: 14,
    '&::marker': {
      color: theme.palette.text.primarySoft
    }
  }
}))

export const OrderHistoryDetails: React.FC<OrderHistoryDetailsProps> = ({order}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classes = useStyles()
  if (!order || !order.orderChanges?.length) return null

  const orderChanges = order.orderChanges.sort((a, b) =>
    (b.changeDate || '') > (a.changeDate || '') ? -1 : 1
  )
  const {quantityUom} = order?.lineItems[0] || {}

  return (
    <Features name="OrderChangeTrail">
      <Grid container data-test-id="order-change-trail">
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
          <CustomHeader title="Order History Details" noDivider />
          <Box className={classes.orderChangesList}>
            {orderChanges.map((oc) => (
              <Box
                key={oc.changeNumber}
                className={classes.orderChange}
                data-test-id={`order-change-${oc.changeNumber}`}
              >
                <FieldValue
                  label={datetimeFormatter(oc.changeDate || '', language)}
                  value={getOrderChangeText(oc, quantityUom, t, language)}
                  gutter
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Features>
  )
}

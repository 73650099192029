import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
// eslint-disable-next-line import/no-cycle
import {Order} from '../../Order.types'

export type UseOrderProps = {
  groupId: string
  customerId: string | undefined
  isGroupedDeliveries: boolean
  useHaulierView: boolean
  isPumpServiceIncluded?: boolean
  includeOrderChanges?: boolean
  isServiceOrderIncluded?: boolean
  excludeCancelledOrders?: boolean
  includeDeliveryInstructions?: boolean
  expandToOrderDocuments?: boolean
}

export const useOrder = ({
  groupId,
  customerId,
  isGroupedDeliveries,
  useHaulierView,
  isPumpServiceIncluded = false,
  includeOrderChanges = false,
  isServiceOrderIncluded = false,
  excludeCancelledOrders = false,
  includeDeliveryInstructions = true,
  expandToOrderDocuments = false
}: UseOrderProps) => {
  const resolvedEndPoint = isGroupedDeliveries ? 'OrdersMiddleware/getgroupedorder' : 'order-items'
  return useQuery(
    [
      'orders',
      {
        groupId,
        isGroupedDeliveries,
        isPumpServiceIncluded,
        customerId,
        includeOrderChanges,
        excludeCancelledOrders,
        includeDeliveryInstructions,
        includeServiceOrder: isServiceOrderIncluded
      }
    ],
    async () => {
      const expand = [
        'deliveryProgress',
        ...(expandToOrderDocuments ? ['orderDocuments'] : [])
      ].join(',')
      const res: AxiosResponse<Order> = await api.get(`/${resolvedEndPoint}/${groupId}`, {
        params: {
          ...(useHaulierView ? {haulierId: customerId || ''} : {customerId: customerId || ''}),
          expand: expand,
          includeOrderChanges,
          excludeCancelledOrders,
          includeDeliveryInstructions,
          ...(isPumpServiceIncluded ? {includePumpService: true} : {}),
          ...(isServiceOrderIncluded ? {includeServiceOrder: true} : {})
        }
      })
      return res.data
    },
    {
      enabled: !!groupId && !!customerId
    }
  )
}

import {Page, Content} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RequestForQuoteForm} from '../../OrderIntake/BulkCement/RequestForQuote/RequestForQuoteForm'

const RequestForQuotePage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Content>
      <Page data-test-id="request-for-quote-page" title={t('requestForQuote.navigationLabel')}>
        <RequestForQuoteForm />
      </Page>
    </Content>
  )
}

export {RequestForQuotePage}
